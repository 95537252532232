import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    platform,
    getScheduleTemplates,
    saveScheduleTemplate,
    updateScheduleTemplate,
    sortScheduleTemplates,
    deleteScheduleTemplate,
  },
};

async function platform() {
  return await axios.get('availability-days/platform');
}

async function getScheduleTemplates() {
  return await axios.get('/pm/showing-schedule-templates');
}

async function saveScheduleTemplate(context, request) {
  return await axios.post('/pm/showing-schedule-templates', request);
}

async function updateScheduleTemplate(context, scheduleTemplate) {
  return await axios.put(`/pm/showing-schedule-templates/${scheduleTemplate.id}`, scheduleTemplate);
}

async function sortScheduleTemplates(context, request) {
  return await axios.put('/pm/showing-schedule-templates/sort', request);
}

async function deleteScheduleTemplate(context, id) {
  return await axios.delete(`/pm/showing-schedule-templates/${id}`);
}
