<template>
  <div
    class="sd-art"
    :class="{'active': active}"
    :style="{width:size, height:heightComputed, 'background-image': `url(${require('@/assets/art/'+art+'.svg')})`}"
  />
</template>

<script>
export default {
  name: 'SdArt',
  props: {
    size: {
      type: String,
      default: '72px',
    },
    height: {
      type: String,
      default: '',
    },
    art: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    heightComputed() {
      return this.height || this.size;
    },
  },

};
</script>

<style lang="scss">
.sd-art {
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  &:hover,&:active,&.active {
    background-position: bottom left;
  }
}
.active {
  .sd-art {
    background-position: bottom left;
  }
}
</style>
