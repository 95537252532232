import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {
    setShowingProfile,
  },
  actions: {
    enable,
    update,
    deactivate,
    deactivationImpact,
    pauseImpact,
    pause,
    unpause,
    leads,
    showingReport,
    reactivate,
    checkWindows,
  },
};

function setShowingProfile(state, showingProfile) {
  state.showingProfile = showingProfile;
}

async function enable({ commit }, payload) {
  const response = await axios.post('pm/showing-profiles', payload);
  commit('setShowingProfile', response);
  return response;
}

async function update({ commit }, payload) {
  const response = await axios.put(`pm/showing-profiles/${payload.id}`, payload);
  commit('setShowingProfile', response);
  return response;
}

async function checkWindows(context, params) {
  const response = await axios.post(`pm/showing-profiles/${params.id}/affected-schedules`, params);
  return response;
}

async function deactivate(context, data) {
  return await axios.post(`pm/unit-showing-profiles/${data.showingProfileId}/deactivate`, data.deactivationPayload);
}

async function deactivationImpact(context, data) {
  return await axios.get(`pm/unit-showing-profiles/${data.showingProfileId}/deactivation-stop-impact`);
}

async function showingReport(context, showingProfileId) {
  return await axios.get(`businesses/${this.getters['Auth/business'].id}/showing-reports/${showingProfileId}`);
}

async function pause(context, data) {
  return await axios.post(`pm/unit-showing-profiles/${data.showingProfileId}/pause`, {
    pause_from: data.pauseFrom,
    paused_until: data.pausedUntil,
    pause_reason: data.pauseReason,
  });
}

async function unpause(context, data) {
  return await axios.post(`pm/unit-showing-profiles/${data.showingProfileId}/unpause`, {
    max_budgeted_tours: data.max_budgeted_tours,
  });
}

async function pauseImpact(context, data) {
  return await axios.get(`pm/unit-showing-profiles/${data.showingProfileId}/deactivation-pause-impact`, {
    params: {
      pause_from: data.pauseFrom,
      paused_until: data.pausedUntil,
    },
  });
}

async function leads(context, showingProfileId) {
  return await axios.get(`pm/unit-showing-profiles/${showingProfileId}/leads`);
}

async function reactivate(context, showingProfileId) {
  return await axios.post(`pm/unit-showing-profiles/${showingProfileId}/reactivate`);
}
