<template>
  <ElDialog
    :center="true"
    top="30vh"
    :show-close="false"
    visible
    :width="$viewport.lt.md ? 'auto': '500px'"
    :fullscreen="$viewport.lt.md"
    @close="$emit('close')"
  >
    {{ message }}
    <div
      slot="footer"
      class="row justify-content-center"
    >
      <div class="col-auto">
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          @click="$emit('close')"
        >
          Cancel
        </ElButton>
      </div>
      <div class="col-auto">
        <ElButton
          class="w-100"
          size="medium"
          @click="$emit('discard')"
        >
          Discard changes
        </ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<script>
export default {
  name: 'SdDiscardChangesDialog',
  props: {
    message: {
      type: String,
      required: false,
      default: 'Discard changes?',
    },
  },
};
</script>
