<template>
  <div
    class="page"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SdPage',
};
</script>

<style lang="scss" scoped>
.page {
    padding: $page-padding-vertical $page-padding-horizontal;

    @include media-breakpoint-up(md) {
        padding: $page-padding-vertical-md $page-padding-horizontal-md;
    }

    ::v-deep {
        .page-header {
            margin-bottom: $page-padding-vertical-md;
            @include media-breakpoint-up(md) {
                margin-bottom: $page-padding-vertical;
            }
        }
    }
}
</style>
