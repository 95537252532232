const titlePrefix = 'Showdigs';

export default function (toRouteObject) {
  if (!toRouteObject.meta || !toRouteObject.meta.title) {
    document.title = titlePrefix;

    return;
  }

  let title = `${titlePrefix} | ${toRouteObject.meta.title}`;

  const titleRouteParam = toRouteObject.meta.titleRouteParam;

  if (titleRouteParam && toRouteObject.params[titleRouteParam]) {
    title += ` #${toRouteObject.params[titleRouteParam]}`;
  }
  document.title = title;
}
