import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    create,
    update,
    destroy,
    updateOrder,
  },
};

async function create(context, payload) {
  return await axios.post('pm/screening-templates', payload);
}
async function update(context, payload) {
  return await axios.put(`pm/screening-templates/${payload.id}`, payload);
}
async function updateOrder(context, order) {
  return await axios.post('pm/screening-templates/update-order', { new_order: order });
}
async function destroy(context, id) {
  return await axios.delete(`pm/screening-templates/${id}`);
}
