import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {
    screeningCriteria: null,
  },
  getters: {
    get,
  },
  mutations: {
    set,
  },

  actions: {
    load,
  },
};

function get(state) {
  return state.screeningCriteria;
}

function set(state, screeningCriteria) {
  state.screeningCriteria = screeningCriteria;
}

async function load({ commit }) {
  const response = await axios.get('pm/screening-criteria');
  commit('set', response);
  return response;
}
