import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    index,
    store,
    checkForDuplicate,
    checkForAssignedUnit,
    checkForCompletedRecently,
  },
};

async function index(context, params) {
  return await axios.get('pm/inspections', { params });
}

async function store(context, params) {
  return await axios.post('pm/inspections', params);
}

async function checkForDuplicate(context, params) {
  return await axios.get(`pm/inspections/check-for-duplicate?${new URLSearchParams(params).toString()}`);
}

async function checkForAssignedUnit(context, params) {
  return await axios.get(`pm/inspections/check-for-assigned-unit?${new URLSearchParams(params).toString()}`);
}

async function checkForCompletedRecently(context, params) {
  return await axios.get(`pm/inspections/check-for-completed-recently?${new URLSearchParams(params).toString()}`);
}
