export default {
  namespaced: true,
  state: {
    prices: [],
  },
  getters: {
    prices,
  },
  mutations: {
    setPrices,
  },
  actions: {},
};

function prices(state) {
  return state.prices.length
    ? state.prices
    : [];
}

function setPrices(state, prices) {
  state.prices = prices;
}
