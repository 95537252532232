<template>
  <div
    class="page-header"
  >
    <div class="row no-gutters align-items-center justify-content-between">
      <component
        :is="$viewport.lt.md ? 'h2' : headerTag"
        v-if="title || withBackButton"
        class="col text-gray-darker mb-0"
      >
        <router-link
          v-if="backRoute"
          :to="backRoute"
          class="d-inline-flex"
        >
          <img
            :style="$viewport.lt.md ? 'height:14px' : ''"
            :src="BackArrow"
          >
        </router-link>
        {{ title }}
        <slot name="title-tag" />
      </component>
      <div class="d-flex justify-content-end align-items-center">
        <slot />
      </div>
    </div>
    <hr
      v-if="hr"
      class="mt-3 d-md-block"
    >
  </div>
</template>

<script>
import BackArrow from '@/assets/back-arrow.svg';

export default {
  name: 'SdPageHeader',
  components: { BackArrow },
  props: {
    headerTag: {
      type: String,
      default: 'h1',
    },
    title: {
      type: String,
      default: '',
    },
    hr: {
      type: Boolean,
      default: false,
    },
    backRoute: {
      type: Object,
      default: null,
    },
    withBackButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { BackArrow };
  },
};

</script>
<style lang="scss" scoped>
    h1{
        font-size: $--font-size-medium-large;
    }
    h2{
        color: gray-color(darker);
        font-weight: $font-weight-bold;
        line-height: $--font-size-medium-large;
        font-size: $--font-size-large;
    }
</style>
