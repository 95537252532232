<template>
  <div id="action-wrapper">
    <div
      v-if="!forceFloatingActionsButtons && ($viewport.gt.sm || isForceDesktopView)"
      class="row gutter-0.75 flex-row"
    >
      <template v-for="(action, key) in visibleActions">
        <ElTooltip
          :key="key"
          :disabled="$viewport.lt.md || !action.tooltipText"
          popper-class="action-popper-class"
          placement="top"
        >
          <template
            v-if="action.tooltipText"
            slot="content"
          >
            <p class="text-center">
              {{ action.tooltipText }}
            </p>
          </template>
          <SdActionButton
            :key="key"
            class="col-auto"
            :action="action"
            :is-force-desktop-view="isForceDesktopView"
            @toggle-action-ui-flag="toggleUiFlag(key)"
          />
        </ElTooltip>
      </template>
    </div>
    <ElDropdown
      v-else
      class="float-action-button"
      :class="isActionActiveRef ? 'active' : ''"
      trigger="click"
      size="small"
      @visible-change="isActionActiveRef = !isActionActiveRef"
    >
      <div class="el-dropdown-link close" />
      <ElDropdownMenu
        slot="dropdown"
        class="flex-column"
      >
        <ElDropdownItem
          v-for="(action, key) in visibleActions"
          :key="key"
          class="d-flex mb-2"
        >
          <SdActionButton
            :action="action"
            @toggle-action-ui-flag="toggleUiFlag(key)"
          />
        </ElDropdownItem>
      </ElDropdownMenu>
    </ElDropdown>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';

export default {
  name: 'SdActionButtons',
  props: {
    actions: {
      type: Object,
      required: true,
    },
    isForceDesktopView: {
      type: Boolean,
      required: false,
      default: false,
    },
    forceFloatingActionsButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    showNew: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isActionActiveRef = ref(false);
    const visibleActions = computed(computeVisibleActions);

    return {
      isActionActiveRef,
      toggleUiFlag,
      visibleActions,
    };

    function toggleUiFlag(actionKey) {
      context.emit('toggle-action-ui-flag', actionKey);
    }

    function computeVisibleActions() {
      const actions = { ...props.actions };
      const keys = Object.keys(props.actions);
      keys.forEach((key) => {
        if (props.actions[key].visible === false) {
          delete actions[key];
        }
      });
      return actions;
    }
  },
};
</script>
<style lang="scss">
#action-wrapper {
  .float-action-button {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 1rem;
    border-radius: 1.5rem;
    z-index: 100;
    width: 3rem;
    height: 3rem;
    box-shadow: $app-box-shadow;
    background: theme-color(primary);
    .new-tag {
      position: absolute;
      z-index: 0;
      pointer-events: all;
      top: 2px;
      left: -22px;
      .el-badge__content {
        font-size: 11px;
        border-radius: $app-border-radius;
        border-color: transparent;
      }
    }
  }

  .close {
    position: relative;
    display: flex;
    align-items: center;
    justify-self: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 300ms;
    -webkit-transition: -webkit-transform 300ms;
  }

  .close::before,
  .close::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 2px;
    left: 14px;
    background: #fff;
  }

  .close::before {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .close::after {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .float-action-button.active .close {
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
.action-popper-class {
  padding: 0.5rem;
  p {
    margin: 0;
    color: $white;
  }
  margin-bottom: 0 !important;
}
</style>
