import { axios } from '@/plugins/Axios';
import momentUtil from '@/utils/MomentUtil';

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    setNotifications,
    markAsRead,
    markAllAsRead,
    addNewNotification,
  },
  actions: {
    read,
    readAll,
  },
};

function setNotifications(state, notifications) {
  state.notifications = notifications.map(formatNotification);
}

function markAsRead(state, notification) {
  notification.read_at = new Date().getTime();
}

function markAllAsRead(state) {
  const readAt = new Date().getTime();
  state.notifications.forEach((notification) => {
    notification.read_at = readAt;
  });
}

function addNewNotification(state, notification) {
  state.notifications.unshift(formatNotification(notification));
}

async function read(context, notification) {
  try {
    await axios.post(`users/${this.getters['Auth/userId']}/notifications/${notification.id}/read`);
  } catch (e) {}
 context.commit('markAsRead', notification);
}

async function readAll(context) {
  await axios.post(`users/${this.getters['Auth/userId']}/notifications/read-all`);
  context.commit('markAllAsRead');
}

function formatNotification(notification) {
  const url = new URL(notification.action_url);
  let message = notification.message;
  while (message.indexOf('**') !== -1) {
    message = message.replace('**', '<strong>').replace('**', '</strong>');
  }

  return {
    ...notification,
    action_url: url.pathname + url.search,
    message,
    created_at: momentUtil(notification.created_at, null).shortFromNow(),
  };
}
