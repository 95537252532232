import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    find,
    activities,
    leads,
    showingSlots,
    add,
    checkFailedInquiries,
    recycleInquiries,
    destroy,
    update,
    search,
    syncUnit,
    getRentEstimate,
  },
};

async function find(context, id) {
  return await axios.get(`pm/units/${id}`);
}

async function activities(context, id) {
  return await axios.get(`pm/units/${id}/activities`);
}

async function leads(context, id) {
  return await axios.get(`pm/units/${id}/leads`);
}

async function showingSlots(context, id) {
  return await axios.get(`units/${id}/showing-slots`);
}

async function checkFailedInquiries(context, id) {
  return await axios.get(`units/${id}/recycled-inquiries`);
}

async function recycleInquiries(context, id) {
  return await axios.put(`units/${id}/recycled-inquiries`);
}

async function update({ commit }, { unitId, unit }) {
  return await axios.put(`pm/units/${unitId}`, unit);
}

async function add(context, payload) {
  const { property_id, name } = payload;
  return await axios.post('units', {
    property_id,
    name,
  });
}

async function destroy(context, id) {
  return await axios.delete(`units/${id}`);
}

async function search(context, params) {
  return await axios.get('pm/units/search', {
    params: {
      page: 1,
      limit: 20,
      searchTerm: params.searchTerm ?? '',
      onlyShowing: params.onlyShowing ? 1 : 0,
      id: params.id ?? null,
    },
  });
}
async function syncUnit(context, unitId) {
  return await axios.post(`pm/units/${unitId}/sync`);
}

async function getRentEstimate(context, unitId) {
  return await axios.get(`pm/units/${unitId}/estimate-rent`);
}
