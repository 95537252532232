<template>
  <div class="loading-layer">
    <transition name="fade">
      <div
        v-if="state.error"
        :key="1"
        class="text-center"
      >
        <div>
          We're sorry, an error occurred.
        </div>
        <div v-if="state.error.message">
          Error: <small>{{ state.error.message }}</small>
        </div>
        <ElButton
          size="small"
          @click="triggerInit()"
        >
          Try Again
        </ElButton>
      </div>
      <div
        v-else-if="state.loading"
        :key="2"
        class="text-center"
      >
        <ElSpinner color="primary" />
      </div>
      <div
        v-else
        :key="3"
        class="content"
      >
        <slot name="loaded" />
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api';
import * as Sentry from '@/utils/SentryUtil';
import HttpError from '@/errors/HttpError';

export default {
  name: 'SdLoadingLayer',
  props: {
    init: {
      type: Function,
      required: true,
    },
    errorMessage: {
      type: String,
      default: () => null,
    },
    manualDispatch: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup(props) {
    const state = reactive({
      loading: true,
      error: null,
    });

    init();

    return { state, triggerInit };

    function init() {
      if (props.manualDispatch) {
        return;
      }

      triggerInit();
    }

    async function triggerInit() {
      try {
        state.loading = true;
        state.error = null;
        await props.init();
        state.loading = false;
      } catch (error) {
        if (process.env.VUE_APP_ENV === 'local') {
          // eslint-disable-next-line no-console
          console.error(
            '[SdLoadingLayer] Loading layer init function error: ',
            error,
          );
        }
        if (props.errorMessage) {
          state.error = new Error(props.errorMessage);
          return;
        }
        if (error instanceof HttpError) {
          if (error.response && error.response.status !== 408) {
            Sentry.captureException(error);
          }
          state.error = error;
          return;
        }
        state.error = error;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.loading-layer {
    display: flex;
    justify-content: center;
    position: relative;

    > div {
        width: 100%;
        &.fade-enter-active,
        &.fade-leave-active {
            transition: opacity 0.2s ease-in-out;
        }

        &.fade-enter,
        &.fade-leave-to {
            width: 100%;
            opacity: 0;
            position: absolute;
        }
    }
}
</style>
