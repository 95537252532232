import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    get,
    subscribe,
    modify,
    cancel,
  },
};

function get() {
  return axios.get('pm/subscriptions');
}

function subscribe({ commit }, params) {
  return axios.post('pm/subscriptions', params);
}

function modify(context) {
  return axios.put('pm/subscriptions'); // changed to default plan - Standard
}

function cancel(context) {
  return axios.delete('pm/subscriptions');
}
