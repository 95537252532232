import { ConditionReport } from '@/constants/ConditionReport';

export const SHOWING_PROFILE_ROUTES = {
  AGENT_SHOWING_PAGE: 'app.showing-profile.add',
  SELF_SHOWING_PAGE: 'app.showing-profile.add-self',
  PUBLISH_LISTING_SHOWING_PAGE: 'app.showing-profile.add-publish-listing',
  TYPE_SELECTION_PAGE: 'app.showing-profile.type-selection',
  TYPE_READY_TO_START_PAGE: 'app.showing-profile.ready-to-start',
};

export default [
  // Auth
  {
    path: '/sign-in',
    name: 'auth.sign-in',
    component: () => import('@/pages/Auth/SignIn'),
    meta: {
      layout: 'AuthLayout',
      title: 'Login',
    },
  },
  {
    path: '/register/:token?',
    name: 'auth.register',
    component: () => import('@/pages/Auth/Register'),
    meta: {
      layout: 'AuthLayout',
      title: 'Sign-Up',
    },
  },
  {
    path: '/markets/map/:market_name',
    name: 'market.market-map',
    component: () => import('@/pages/Market/MarketMap'),
    meta: {
      layout: 'MapLayout',
      title: 'Market',
    },
  },
  {
    path: '/user/:id(\\d+)/verify/:signature',
    name: 'auth.verify',
    component: () => import('@/pages/Auth/Verify'),
    meta: {
      layout: 'AuthLayout',
      title: 'Verify Email',
    },
  },
  {
    path: '/subscribe',
    name: 'app.subscribe',
    component: () => import('@/pages/App/Subscribe'),
    meta: {
      layout: 'AuthLayout',
      title: 'Subscribe',
    },
  },
  // Lease
  {
    path: '/lease',
    name: 'app.lease',
    component: () => import('@/pages/App/Lease/Lease'),
    meta: {
      title: 'Leasing',
      fullWidthContainer: true,
    },
  },
  {
    path: '/devices',
    name: 'app.devices',
    component: () => import('@/pages/App/Device/Device'),
    meta: {
      title: 'Devices',
      fullWidthContainer: true,
    },
  },
  {
    path: '/business-address',
    name: 'app.business-address',
    component: () => import('@/pages/App/BusinessAddress'),
    meta: {
      layout: 'AuthLayout',
      title: 'Business Address',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth.forgot-password',
    component: () => import('@/pages/Auth/ForgotPassword'),
    meta: {
      layout: 'AuthLayout',
      title: 'Forgot Password',
    },
  },
  {
    path: '/password-reset/:token',
    name: 'auth.password-reset',
    component: () => import('@/pages/Auth/ForgotPassword'),
    meta: {
      layout: 'AuthLayout',
      title: 'Reset Password',
    },
  },
  {
    path: '/impersonation',
    name: 'auth.impersonation',
    component: () => import('@/pages/Auth/Impersonation'),
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/user-limitation',
    name: 'auth.limited-user',
    component: () => import('@/pages/Auth/LimitedUser'),
    meta: {
      layout: 'BlankLayout',
    },
  },
  // Profile
  {
    path: '/profile/settings',
    name: 'app.profile.settings',
    component: () => import('@/pages/App/Profile/Settings'),
    meta: {
      whiteBackground: true,
      title: 'Profile',
    },
  },
  {
    path: '/profile/business-settings/:section?',
    name: 'app.profile.business-settings',
    component: () => import('@/pages/App/Profile/BusinessSettings'),
    meta: {
      whiteBackground: true,
      title: 'Settings',
    },
  },
  {
    path: '/transactions',
    name: 'app.transactions',
    component: () => import('@/pages/App/Transactions'),
    meta: {
      whiteBackground: true,
      title: 'Transactions',
    },
  },
  {
    path: '/billing',
    name: 'app.billing',
    component: () => import('@/pages/App/Billing'),
    meta: {
      whiteBackground: true,
      title: 'Billing',
    },
  },
  // Pulse
  {
    path: '/pulse',
    name: 'app.pulse',
    component: () => import('@/pages/App/Pulse'),
    meta: {
      title: 'Pulse',
    },
  },
  // Properties
  {
    path: '/properties',
    name: 'app.properties',
    component: () => import('@/pages/App/Properties'),
    meta: {
      title: 'Properties',
    },
  },
  {
    path: '/',
    redirect: '/properties',
  },
  {
    path: '/properties/add',
    name: 'app.properties.add',
    component: () => import('@/pages/App/PropertyAdd'),
    meta: {
      whiteBackground: true,
      title: 'Add Property',
    },
  },

  // Units
  {
    path: '/properties/:id(\\d+)/units',
    name: 'app.units',
    component: () => import('@/pages/App/PropertyUnits'),
    meta: {
      title: 'Units in Property',
      titleRouteParam: 'id',
    },
  },
  {
    path: '/properties/:id(\\d+)/units/:unit_id(\\d+)',
    name: 'app.units.show',
    component: () => import('@/pages/App/Unit/Unit'),
    meta: {
      title: 'Unit',
      titleRouteParam: 'unit_id',
    },
  },
  {
    path: '/properties/:id(\\d+)/units/:unit_id(\\d+)/edit',
    name: 'app.properties.edit',
    component: () => import('@/pages/App/PropertyEdit'),
    meta: {
      whiteBackground: true,
      title: 'Edit Unit',
      titleRouteParam: 'unit_id',
    },
  },
  {
    path: '/properties/:id(\\d+)/services',
    name: 'app.units.services',
    component: () => import('@/pages/App/Unit/UnitBookAService'),
    meta: {
      whiteBackground: true,
      title: 'Book a Service',
    },
  },
  {
    path: '/properties/:id(\\d+)/units/:unit_id(\\d+)/services',
    name: 'app.units.services',
    component: () => import('@/pages/App/Unit/UnitBookAService'),
    meta: {
      whiteBackground: true,
      title: 'Book a Service',
    },
  },

  // Showing Profile
  {
    path: '/properties/:id(\\d+)/showing-profile/ready-to-start',
    name: SHOWING_PROFILE_ROUTES.TYPE_READY_TO_START_PAGE,
    component: () => import('@/pages/App/ShowingProfile/ShowingProfileReadyToStart'),
    meta: {
      whiteBackground: true,
      title: 'Start Showing',
    },
  },
  {
    path: '/properties/:id(\\d+)/units/:unit_id(\\d+)/showing-profile/showing-type-selection',
    name: SHOWING_PROFILE_ROUTES.TYPE_SELECTION_PAGE,
    component: () => import('@/pages/App/ShowingProfile/ShowingTypeSelection'),
    meta: {
      whiteBackground: true,
      title: 'Start Showing',
    },
  },
  {
    path: '/properties/:id(\\d+)/units/:unit_id(\\d+)/showing-profile/add',
    name: SHOWING_PROFILE_ROUTES.AGENT_SHOWING_PAGE,
    component: () => import('@/pages/App/ShowingProfile/ShowingProfileAdd'),
    meta: {
      whiteBackground: true,
      title: 'Start Showing',
    },
  },
  {
    path: '/properties/:id(\\d+)/units/:unit_id(\\d+)/self-showing-profile/add',
    name: SHOWING_PROFILE_ROUTES.SELF_SHOWING_PAGE,
    component: () => import('@/pages/App/ShowingProfile/SelfShowingProfileAdd'),
    meta: {
      whiteBackground: true,
      title: 'Start Showing',
    },
  },
  {
    path: '/properties/:id(\\d+)/units/:unit_id(\\d+)/publish-listing-showing-profile/add',
    name: SHOWING_PROFILE_ROUTES.PUBLISH_LISTING_SHOWING_PAGE,
    component: () => import('@/pages/App/ShowingProfile/PublishListingShowingProfileAdd'),
    meta: {
      whiteBackground: true,
      title: 'Start Showing',
    },
  },

  // Prospects
  {
    path: '/prospects',
    name: 'app.prospects',
    component: () => import('@/pages/App/Unit/Unit'),
    meta: {
      title: 'Prospects',
    },
  },
  {
    path: '/prospects/:id',
    name: 'app.prospect.show',
    component: () => import('@/pages/App/Prospect'),
    meta: {
      title: 'Prospect',
    },
  },
  // Tours
  {
    path: '/tours',
    name: 'app.tours',
    component: () => import('@/pages/App/Tours/Tours'),
    meta: {
      title: 'Tours',
    },
  },
  {
    path: '/tours/:id(\\d+)',
    name: 'app.tours.show',
    component: () => import('@/pages/App/Tours/Tour'),
    meta: {
      title: 'Tour',
      titleRouteParam: 'id',
    },
  },

  {
    path: '*',
    component: () => import('@/pages/App/error-page'),
    meta: {
      whiteBackground: true,
    },
  },

  // Condition Reports
  {
    path: '/condition-reports',
    name: 'app.condition-reports',
    component: () => import('@/pages/App/ConditionReports'),
    meta: {
      title: 'Condition Reports',
    },
  },
  {
    path: '/properties/:id(\\d+)?/units/:unit_id(\\d+)?/condition-report/add',
    name: 'app.condition-report.add',
    component: () => import('@/pages/App/ConditionReport/ConditionReportAdd'),
    meta: {
      whiteBackground: true,
      title: 'Order a Report',
    },
  },
  {
    path: '/properties/:id(\\d+)?/units/:unit_id(\\d+)?/condition-report/periodic',
    name: 'app.condition-report.periodic',
    component: () => import('@/pages/App/ConditionReport/ConditionReportPeriodic'),
    meta: {
      whiteBackground: true,
      title: 'Order a Report',
      reportType: ConditionReport.REPORT_TYPE_PERIODIC,
    },
  },
  {
    path: '/properties/:id(\\d+)?/units/:unit_id(\\d+)?/condition-report/move-in-out',
    name: 'app.condition-report.move_in_out',
    component: () => import('@/pages/App/ConditionReport/ConditionReportMoveInOut'),
    meta: {
      whiteBackground: true,
      title: 'Order a Report',
      reportType: ConditionReport.REPORT_TYPE_IN_OUT,
    },
  },
  {
    path: '/properties/:id(\\d+)?/units/:unit_id(\\d+)?/condition-report/vacancy-check',
    name: 'app.condition-report.vacancy_check',
    component: () => import('@/pages/App/ConditionReport/ConditionReportVacancyCheck'),
    meta: {
      whiteBackground: true,
      title: 'Order a Report',
      reportType: ConditionReport.REPORT_TYPE_VACANCY_CHECK,
    },
  },
];
