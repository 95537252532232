const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
let elements;
let viewport;

function initElements() {
  const container = document.getElementById('viewport-breakpoints-container');
  elements = {
    xs: container.querySelector('#xs'),
    sm: container.querySelector('#sm'),
    md: container.querySelector('#md'),
    lg: container.querySelector('#lg'),
    xl: container.querySelector('#xl'),
  };
}

function initViewport(Vue) {
  viewport = {
    ...breakpointValues('isBlock'),
  };
  viewport.lt = {
    ...breakpointValues('lt'),
  };
  viewport.gt = {
    ...breakpointValues('gt'),
  };

  Vue.prototype.$viewport = viewport;
}

function isBlock(breakpoint) {
  return getComputedStyle(elements[breakpoint]).display === 'block';
}

function ltOrGt(breakpoint, statement) {
  let index = breakpoints.indexOf(breakpoint);
  const length = breakpoints.length;

  for (
    ;
    statement === 'gt' ? index < length : index > 0;
    statement === 'gt' ? index++ : index--
  ) {
    const bp = breakpoints[statement === 'gt' ? index + 1 : index - 1];
    if (viewport[bp]) {
      return true;
    }
  }

  return false;
}

function lt(breakpoint) {
  return ltOrGt(breakpoint, 'lt');
}

function gt(breakpoint) {
  return ltOrGt(breakpoint, 'gt');
}

const methods = {
  isBlock,
  lt,
  gt,
};

function breakpointValues(method) {
  return {
    xs: methods[method]('xs'),
    sm: methods[method]('sm'),
    md: methods[method]('md'),
    lg: methods[method]('lg'),
    xl: methods[method]('xl'),
  };
}

function install(Vue) {
  initElements();
  initViewport(Vue);
  window.addEventListener('resize', () => initViewport(Vue));
}

const Viewport = {
  install,
};

export default Viewport;
