<template>
  <div
    class="radio-block"
    :class="{ checked: isChecked, disabled: disabled }"
    @click="check"
  >
    <div class="radio-icon">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="4.5"
          y="4.82422"
          width="15"
          height="15"
          rx="7.5"
          :stroke="scssVariables.darkGray"
        />
        <transition name="fade">
          <circle
            v-if="isChecked"
            cx="12"
            cy="12.3242"
            r="4"
            :fill="scssVariables.primary"
          />
        </transition>
      </svg>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import scssVariables from '../../../styles/export.scss';

export default {
  name: 'SdRadioBlock',
  props: {
    value: {
      type: [Object, Array, Number, Boolean, String],
      required: true,
    },
    label: {
      type: [Object, Array, Number, Boolean, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const isChecked = computed(() => props.value === props.label);

    return {
      isChecked,
      check,
      scssVariables,
    };

    function check() {
      if (props.disabled) {
        return;
      }
      context.emit('input', props.label);
    }
  },
};
</script>

<style lang="scss" scoped>
.radio-block {
  border-radius: $app-border-radius-large;
  padding: 1.5rem;
  border: 1px solid gray-color(light);
  transition: $transition-base;
  display: flex;
  cursor: pointer;

  &.checked {
    border-color: theme-color(primary);
    background: theme-color(primary-lightest);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  > .radio-icon {
    margin-right: 10px;
  }
}
</style>
