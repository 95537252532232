export default {
  name: 'lazy',
  async inserted(el, binding) {
    if (!('IntersectionObserver' in window)
            && !('IntersectionObserverEntry' in window)
            && !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
      await import('intersection-observer');
    }

    createObserver();

    function createObserver() {
      const options = {
        root: null,
        threshold: '0',
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }
    function loadImage() {
      el.src = binding.value;
    }
  },
};
