import * as Sentry from '@sentry/vue';
import Vue from 'vue';

export {
  initSentry, setUser, deleteUser, captureException,
};

function initSentry() {
  const dsn = process.env.VUE_APP_SENTRY_DSN;
  if (dsn) {
    Sentry.init({
      Vue,
      dsn,
      ignoreErrors: [
        'ChunkLoadError',
        'NavigationDuplicated',
        'Redirected when going from',
        "Cannot read property '$mapPromise'",
        "Cannot read properties of undefined (reading '$mapPromise')",
        'Network Error',
        'Navigation cancelled from',
        'Loading CSS chunk',
      ],
      logErrors: process.env.VUE_APP_ENV !== 'production',
      debug: process.env.VUE_APP_ENV !== 'production',
      sendDefaultPii: true,
      environment: process.env.VUE_APP_ENV,
    });
  }
}

function setUser(user) {
  Sentry.setUser({
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
  });
}

function deleteUser() {
  Sentry.setUser(null);
}

// read about it here: https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly
// scope can contain the following: tags, extra, contexts, user, level, fingerprint
// try keeping context data to a minimum, Sentry will truncate large nested objects
function captureException(error, scope = {}) {
  return Sentry.captureException(error, scope);
}
