<template>
  <div
    ref="formElementRef"
    class="fl-container"
    :class="classObject"
  >
    <label
      class="fl-label"
      :style="{width: width}"
      :for="inputId"
    >
      {{ floatLabel }}
    </label>
    <label
      v-if="showLowerOptional"
      :for="inputId"
      class="input-under-label"
    >(optional)</label>
    <slot />
  </div>
</template>

<script>
import {
  computed, onBeforeUnmount, onMounted, onUpdated, ref,
} from '@vue/composition-api';

const nonFormElementsSelector = '.el-select .el-select__tags + .el-input';
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    forInput: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: 'auto',
    },
    withOptional: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const nonFormElement = ref(null);
    const formElementRef = ref(null);
    const formEl = ref(undefined);
    const labelEl = ref(undefined);
    const isActive = ref(false);
    const isFocused = ref(false);
    const inputId = computed(() => props.forInput);
    const showLowerOptional = computed(() => !isActive.value && props.withOptional);
    const classObject = computed(() => ({
      'fl-input': isActive.value,
      'fl-focus': isFocused.value,
      'fl-with-optional': !!props.withOptional && showLowerOptional.value,
    }));
    const formElType = computed(() => (formEl.value ? formEl.value.tagName.toLowerCase() : ''));

    const floatLabel = computed(() => {
      if (props.label) {
        return props.label;
      }
      switch (formElType.value) {
        case 'input':
        case 'textarea':
          return formEl.value.placeholder + (props.withOptional ? ' (optional)' : '');
        case 'select':
          return formEl.value.querySelector('option[disabled][selected]').innerHTML;
        case 'div': // supporting element form components that don't translate to native html form elements
          return formEl.value.querySelector('input').placeholder;
        default:
          return '';
      }
    });
    onMounted(() => {
      nonFormElement.value = formElementRef.value.querySelector(nonFormElementsSelector);
      if (nonFormElement.value) {
        mountNonFormElement();
      } else {
        mountFormElement();
      }
    });
    onBeforeUnmount(() => {
      formEl.value.removeEventListener('blur', updateIsFocused);
      formEl.value.removeEventListener('focus', updateIsFocused);
      formEl.value = undefined;
      formElementRef.value = undefined;
    });
    onUpdated(() => {
      updateIsActive();
    });

    return {
      formEl,
      labelEl,
      isFocused,
      showLowerOptional,
      isActive,
      classObject,
      inputId,
      floatLabel,
      formElementRef,
    };

    function focusFormEl() {
      formEl.value.focus();
    }

    function updateIsActive() {
      if (nonFormElement.value) {
        isActive.value = props.active;
      } else {
        isActive.value = formEl.value.value.length > 0;
      }
    }

    function updateIsFocused() {
      isFocused.value = formEl.value === document.activeElement && isActive.value;
    }

    function mountFormElement() {
      formEl.value = formElementRef.value.querySelector('input, textarea, select');
      formEl.value.addEventListener('blur', updateIsFocused);
      formEl.value.addEventListener('focus', updateIsFocused);

      if (!props.forInput) {
        labelEl.value = formElementRef.value.querySelector('label');
        labelEl.value.addEventListener('click', focusFormEl);
      }

      if (formEl.value.value.length > 0) {
        isActive.value = true;
      }
    }

    function mountNonFormElement() {
      formEl.value = formElementRef.value.querySelector(nonFormElementsSelector);
      formEl.value.addEventListener('blur', updateIsFocused);
      formEl.value.addEventListener('focus', updateIsFocused);

      // handling multi select case
      if (formEl.value.className.includes('multiple-select')) {
        mountMultiSelect();
      }

      // support future non form elements mount here...
    }

    function mountMultiSelect() {
      const tags = formEl.value.querySelectorAll('.el-tag');
      if (tags.length) {
        isActive.value = true;
      }
    }
  },

};
</script>

<style lang="scss">
.fl-container:hover {
  .el-input__inner {
    box-shadow: inset 0px 0px 1px 1px $--border-color-base;
  }
}

.fl-container {
  position: relative;

  .input-under-label {
    font-size: $--font-size-extra-small !important;
    position: absolute;
    top: 7px !important;
    padding-left: 16px;
    color: $--color-text-placeholder !important;
    z-index: 1;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transform: translate(0, 16px) scale(1);
    transform: translate(0, 26px) scale(1);
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    pointer-events: none;
  }

  .fl-label {
    position: absolute;
    right: 1px;
    left: 1px;
    top: 1px;
    text-indent: 15px;
    line-height: 1rem;
    font-size: $--font-size-medium;
    overflow: hidden;
    color: gray-color(dark);
    background-color: gray-color('lighter');
    border-radius: $app-border-radius;
    padding-top: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    z-index: 1;
  }

  &.fl-input {
    > label {
      opacity: 1;
      font-size: $--font-size-small;
      text-align: left;
    }

    .el-input__inner {
      padding-top: 1rem;
    }

    .el-textarea__inner {
      padding-top: 1.5rem;
    }

    .el-select__tags {
      top: initial;
      transform: none;
      padding: 25px 15px 0;

      .el-tag{
        margin: 2px 5px 2px 0;
      }
    }
  }

  &.fl-focus {
    label {
      color: theme-color(primary);
    }
  }

  input:focus, textarea:focus, select:focus {
    outline: 0;
  }
}

.fl-with-optional .el-input__inner {
  padding-bottom: 10px;
}
</style>
