export default {
  LEGACY: 'legacy',
  BASIC: 'basic',
  PRO: 'pro',
  PREMIUM: 'premium',
  STANDARD: 'standard',
  MONTHLY_STANDARD: 'monthly standard',
  QUARTERLY_STANDARD: 'quarterly standard',
  ANNUAL_STANDARD: 'annual standard',
  get STANDARD_TYPE_PLANS() {
    return [this.STANDARD, this.MONTHLY_STANDARD, this.QUARTERLY_STANDARD, this.ANNUAL_STANDARD];
  },
};
