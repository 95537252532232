<template>
  <div class="property-address-field">
    <ElTooltip
      :visible-arrow="false"
      :disabled="$viewport.lt.md"
      popper-class="popover-panel"
      placement="right-start"
    >
      <template slot="content">
        <i class="sdicon-info-circle" />
        <p>Property address will be shown to prospects and agents.</p>
      </template>
      <SdFloatLabel label="Address">
        <ElAutocomplete
          v-model="fieldState.state"
          :validate-event="false"
          :fetch-suggestions="querySearchAsync"
          :popper-class="fieldState.noResults ? 'no-results' : ''"
          placeholder="Property address"
          class="col-12 col-md-12"
          :disabled="disabled"
          @select="selected"
          @input="clearIfEmpty"
        >
          <template slot-scope="{ item }">
            <div class="value">
              {{ item.value }}
            </div>
            <span class="link">
              {{ item.link }}
            </span>
          </template>
        </ElAutocomplete>
        <div ref="a" />
      </SdFloatLabel>
    </ElTooltip>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import { showErrorNotify } from '@/utils/NotifyUtil';
import Regex from '@/constants/Regex';

const regexConstants = Regex;

/* eslint-disable no-undef */
export default {
  name: 'SdAddressField',
  props: {
    address: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const fieldState = reactive({
      state: props.address ? props.address.value : null,
      noResults: false,
    });
    const fileList = ref(null);
    const googleServices = reactive(defaultGoogleServices);
    const refs = context.refs;

    onMounted(() => {
      googleServices.googlePlacesAutocompleteService = new google.maps.places.AutocompleteService();
      googleServices.googlePlacesService = new google.maps.places.PlacesService(refs.a);
    });

    return {
      fieldState,
      fileList,
      querySearchAsync,
      selected,
      clearIfEmpty,
    };

    async function querySearchAsync(queryString, cb) {
      let results = [];
      if (queryString) {
        queryString = createFilter(queryString);
        googleServices.googlePlacesAutocompleteService.getPlacePredictions({
          input: queryString,
          componentRestrictions: {
            country: process.env.VUE_APP_GOOGLE_PLACES_COUNTRIES_AVAILABLE.split(' '),
          },
          types: ['address'],
        }, (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            fieldState.noResults = false;

            results = (predictions.map((prediction) => ({
              value: prediction.description,
              placeId: prediction.place_id,
            })));
          } else {
            fieldState.noResults = true;

            results = [
              {
                link: 'No results. Try removing unnecessary details such as unit or building names',
                value: null,
              },
            ];
          }

          cb(results);
        });
      }

      cb(results);
    }

    function createFilter(queryString) {
      return queryString.replace(regexConstants.id, '');
    }

    function selected(item, navigatedWithKeyboard) {
      if (!navigatedWithKeyboard && item.value !== null) {
        googleServices.googlePlacesService.getDetails({
          placeId: item.placeId,
        }, (place, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            showErrorNotify(context, 'Error occurred');
          } else {
            const {
              formatted_address: value, name, geometry: { location: { lat: latitude, lng: longitude } }, ...data
            } = place;
            const nameWithAddressNumber = concatAddressNumberIfExists(name);
            const valueWithAddressNumber = concatAddressNumberIfExists(value);
            context.emit('set-address-field', {
              value: valueWithAddressNumber,
              name: nameWithAddressNumber,
              data,
              geo_point: {
                latitude: latitude(),
                longitude: longitude(),
              },
            });
          }
        });
      }
    }

    function clearIfEmpty() {
      if (fieldState.state === '') {
        context.emit('set-address-field', null);
      }
    }

    function concatAddressNumberIfExists(name) {
      const firstAddressChunck = fieldState.state.split(' ')[0];
      const firstNameChunk = name.split(' ')[0];
      if (isNumber(firstAddressChunck) && !isNumber(firstNameChunk)) {
        return `${firstAddressChunck} ${name}`;
      }
      return name;
    }

    function isNumber(str) {
      return /^\d+$/.test(str);
    }
  },
};

const defaultGoogleServices = {
  googlePlacesAutocompleteService: null,
  googlePlacesService: null,
};
</script>

<style lang="scss">
    .property-address-field {
        .el-autocomplete {
            padding: 0;
        }
    }

    .el-autocomplete-suggestion {
        &.no-results {
            li {
                pointer-events: none;
            }
        }
    }
</style>
