import Inquiry, { InquiryExtendedStatuses } from '@/constants/Inquiry';
import momentUtil from '@/utils/MomentUtil';
import { isTourStatusIssue } from '@/utils/TourUtil';
import { isInquiryPendingVerification } from '@/utils/IdentityVerificationUtil';
import get from 'lodash.get';

export const getInquiryStatus = (inquiry, lead, tour, showingProfile) => {
  const isTourIssue = isTourStatusIssue(tour);

  if (isTourIssue) {
    return InquiryExtendedStatuses.NOT_TOURED;
  }

  if (inquiry.is_disqualified) {
    return InquiryExtendedStatuses.DISQUALIFIED;
  }

  const isPendingVerification = isInquiryPendingVerification(inquiry, lead, showingProfile);

  if (isPendingVerification) {
    return InquiryExtendedStatuses.UNVERIFIED;
  }

  return inquiry.status;
};

const statusDescriptions = {
  [Inquiry.STATUS_NEW]: 'Inquired',
  [Inquiry.STATUS_PENDING]: 'Inquired',
  [Inquiry.STATUS_IN_PROGRESS]: 'Scheduled',
  [Inquiry.STATUS_SCHEDULED]: 'Scheduled',
  [Inquiry.STATUS_COMPLETED]: 'Toured',
  [InquiryExtendedStatuses.NOT_TOURED]: 'Not toured',
  [InquiryExtendedStatuses.UNVERIFIED]: 'Unverified',
  [InquiryExtendedStatuses.DISQUALIFIED]: 'Disqualified',
};

const statusBadgeColors = {
  [Inquiry.STATUS_NEW]: 'orange',
  [Inquiry.STATUS_PENDING]: 'orange',
  [Inquiry.STATUS_IN_PROGRESS]: 'purple',
  [Inquiry.STATUS_SCHEDULED]: 'purple',
  [Inquiry.STATUS_COMPLETED]: 'green',
  [InquiryExtendedStatuses.NOT_TOURED]: 'red',
  [InquiryExtendedStatuses.UNVERIFIED]: 'red',
  [InquiryExtendedStatuses.DISQUALIFIED]: 'red',
};

export function getInquiryBadgeFormatStatus(type) {
  return {
    label: statusDescriptions[type] || '',
    color: statusBadgeColors[type] || 'gray',
    bold: true,
    minWidth: '85px',
  };
}

export const getInquiryActivityDescription = (inquiryCreatedAt, propertyTimeZone) => {
  const inquiredAt = momentUtil(inquiryCreatedAt, propertyTimeZone || null);
  return `Inquired on ${inquiredAt.toDisplayFormatMonthWithDayAndShortTimeFormat()}`;
};

export function inquiryLeadNameFilterFn(query = '') {
  function prepareStringToFilter(query) {
    const NO_SPECIAL_CHARS_AND_SPACES_REGEX = /[^a-zA-Z0-9]/g;
    return String(query)
      .replace(NO_SPECIAL_CHARS_AND_SPACES_REGEX, '')
      .toLocaleLowerCase();
  }

  const relevantAttributes = ['phone_number', 'email', 'full_name'];
  const queryToFilterBy = prepareStringToFilter(query);

  return (inquiry) => !query.length
    || relevantAttributes.some((attribute) => prepareStringToFilter(get(inquiry, `lead[${attribute}]`)).includes(queryToFilterBy));
}

export function inquiryLocationFilterFn(locations = []) {
  return (inquiry) => !locations.length
    || locations.some(
      (stateAndCity) => {
        const [state, vicinity] = stateAndCity;
        return get(inquiry, 'unit.property.state') === state
          && get(inquiry, 'unit.property.vicinity') === vicinity;
      },
    );
}

export function inquiryAddressFilterFn(addresses = []) {
  return (inquiry) => !addresses.length
    || addresses.includes(get(inquiry, 'unit.property.short_address'));
}

export function inquiryBedsFilterFn(bedrooms = []) {
  const isFilteredByFourOrMoreBeds = bedrooms.includes('4');
  return (inquiry) => !bedrooms.length
    || bedrooms.includes(get(inquiry, 'unit.bedrooms'))
    || (isFilteredByFourOrMoreBeds && parseInt(get(inquiry, 'unit.bedrooms')) >= 4);
}

export function inquiryMemberFilterFn(members = []) {
  return (inquiry) => !members.length
    || members.includes(inquiry.listing_manager_id);
}
