export const ConditionReport = {
  REPORT_TYPE_IN_OUT: 'move_in_out',
  REPORT_TYPE_PERIODIC: 'periodic',
  REPORT_TYPE_VACANCY_CHECK: 'vacancy_check',
};

export const ReportHumanNames = {
  [ConditionReport.REPORT_TYPE_IN_OUT]: 'Move-in/out',
  [ConditionReport.REPORT_TYPE_PERIODIC]: 'Periodic',
  [ConditionReport.REPORT_TYPE_VACANCY_CHECK]: 'Vacant property check',
};

export const MoveInOutReportTypes = {
  ONE_BD_OR_STUDIO: 1,
  TWO_TO_THREE_BD: 2,
  FOUR_BD_PLUS: 3,
};

export const ConditionReportStates = {
  ORDERED: 'condition-report-ordered',
  PLANNING: 'condition-report-planning',
  WAITING_FOR_TENANT: 'condition-report-waiting_for_tenant',
  SCHEDULED: 'condition-report-scheduled',
  REVIEWING: 'condition-report-reviewing',
  COMPLETED: 'condition-report-completed',
  ON_HOLD: 'condition-report-on_hold',
  ON_HOLD_TENANT: 'condition-report-on_hold_tenant',
  CANCELLED: 'condition-report-cancelled',
};

export const ConditionReportStateTypes = {};
Object.keys(ConditionReportStates).forEach((stateType) => ConditionReportStateTypes[stateType] = stateType.toLocaleLowerCase());
