const Inquiry = {
  STATUS_NEW: 'new',
  STATUS_PENDING: 'pending',
  STATUS_IN_PROGRESS: 'in.progress',
  STATUS_SCHEDULED: 'scheduled',
  STATUS_COMPLETED: 'completed',
  STATUS_MATCHING_FAILED: 'matching.failed',
};

export const InquiryExtendedStatuses = {
  NOT_TOURED: 'not-toured',
  UNVERIFIED: 'unverified',
  DISQUALIFIED: 'disqualified',
};

export default Inquiry;
