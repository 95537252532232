import store from '@/store';

function getSettingValueByKey(key, marketId = null) {
  const setting = store.getters['Settings/settings'].find((settings) => settings.key === key);
  if (setting) {
    let marketSetting;

    if (marketId) {
      marketSetting = setting.children.find((childSetting) => childSetting.market_id === marketId);
    }
    return marketSetting ? marketSetting.value : setting.value;
  }
  return null;
}

export { getSettingValueByKey };
