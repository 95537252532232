const bareAxios = require('axios').default;

export default function () {
  loadVersion();

  window.addEventListener('load', restartVersionTimer, true);
  window.addEventListener('mousemove', restartVersionTimer, true);
  window.addEventListener('mousedown', restartVersionTimer, true);
  window.addEventListener('touchstart', restartVersionTimer, true);
  window.addEventListener('click', restartVersionTimer, true);
  window.addEventListener('keypress', restartVersionTimer, true);
  window.addEventListener('scroll', restartVersionTimer, true);

  restartVersionTimer();

  window.blurred = false;

  window.onblur = () => {
    window.blurred = true;
  };

  window.onfocus = async () => {
    if (!window.blurred) {
      return;
    }

    await checkVersion();
  };

  async function checkVersion() {
    const currentVersion = await loadVersion();

    if (window.currentVersion && currentVersion) {
      if (window.currentVersion !== currentVersion) {
        window.location.reload();
      }
    }
  }

  async function loadVersion() {
    try {
      const response = await bareAxios.get('/json/version.json', {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!window.currentVersion) {
        window.currentVersion = response.data.currentVersion;
      }

      return response.data.currentVersion;
    } catch (e) {
      return '';
    }
  }

  async function restartVersionTimer() {
    const TWO_HOURS = 2 * 60 * 60 * 1000;

    clearTimeout(window.timeoutHolder);
    window.timeoutHolder = setTimeout(async () => {
      await checkVersion();
      await restartVersionTimer();
    }, TWO_HOURS);
  }
}
