<template>
  <div
    class="sd-banner"
    :class="bgClass"
  >
    <div :class="withContainerClass ? 'container' : ''">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SdBanner',
  props: {
    bgClass: {
      type: String,
      default: 'bg-primary',
    },
    withContainerClass: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sd-banner {
    color: $white;
    padding: 1rem;

    .container {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
