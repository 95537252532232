<template>
  <span
    v-if="!!phoneNumber"
    class="sd-phone-line-indicator font-13"
    :class="bgClass"
    @click.stop=""
  >
    <ElLink
      v-if="withPhoneText"
      :underline="false"
      :href="`tel:${phoneNumber}`"
      class="px-0 py-0 font-size-inherit"
    >
      <i
        v-if="withIcon"
        class="sdicon-phone-fill position-relative font-size-inherit"
        @click="window.open(`tel:${phoneNumber}`)"
      />
      {{ formattedPhoneNumber || phoneNumber }}
    </ElLink>
    <div
      v-if="isComfirmed"
      class="prospect-verified d-flex justify-content-center align-items-center overflow-hidden ml-1 bg-greeneyes rounded-circle text-white font-11"
    >
      <i class="sdicon-check" />
    </div>
  </span>
</template>

<script>

export default {
  name: 'SdPhoneIndicator',
  props: {
    withIcon: {
      type: Boolean,
      default: false,
    },
    withPhoneText: {
      type: Boolean,
      default: true,
    },
    bgClass: {
      type: String,
      default: 'white',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    formattedPhoneNumber: {
      type: String,
      default: '',
    },
    isComfirmed: {
      type: Boolean,
      default: false,
    },
  },

};
</script>

<style lang="scss" >
.sd-phone-line-indicator {

  i {
    margin-right: -1px;
    transform: scale(0.7);
  }

  .prospect-verified {
    width: 12px;
    height: 12px;
  }

  a[href^="tel:"] span {
    position: relative;
    color: theme-color("primary");
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
      color: theme-color("primary-dark");
    }
  }
}
</style>
