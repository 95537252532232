import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    get,
    getMarket,
    hasAgents,
  },
};

async function get(context, location) {
  return (await axios.get('pm/markets', {
    params: { location },
  }))[0];
}
async function hasAgents(context, marketId) {
  const results = await axios.get(`pm/markets/${marketId}/has-agents`);
  return results.hasAgents ?? false;
}
async function getMarket(context, market) {
  return (await axios.get(`public/markets/map/${market}`))[0];
}
