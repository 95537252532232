import { capitalizeFirstLatter } from '@/utils/StringUtil';

export const BusinessSourceTypes = {
  APPFOLIO: 'APPFOLIO',
  BUILDIUM: 'BUILDIUM',
  RENTVINE: 'RENTVINE',
  PROPERTYWARE: 'PROPERTYWARE',
  // ENTRATA: 'entrata',
  // REALPAGE: 'realpage',
  // RENTMANAGER: 'rentmanager',,
  // REALPAGE_RPX: 'realpage_rpx',
  // MRI: 'mri',
  // RESMAN: 'resman',
};

export const BusinessSourceTypesOptions = [
  {
    label: 'AppFolio',
    value: BusinessSourceTypes.APPFOLIO,
  },
  {
    label: capitalizeFirstLatter(BusinessSourceTypes.BUILDIUM),
    value: BusinessSourceTypes.BUILDIUM,
  },
  {
    label: capitalizeFirstLatter(BusinessSourceTypes.RENTVINE),
    value: BusinessSourceTypes.RENTVINE,
  },
  {
    label: capitalizeFirstLatter(BusinessSourceTypes.PROPERTYWARE),
    value: BusinessSourceTypes.PROPERTYWARE,
  },
  // {
  //   label: capitalizeFirstLatter(BusinessSourceTypes.ENTRATA),
  //   value: BusinessSourceTypes.ENTRATA,
  // },
  // {
  //   label: capitalizeFirstLatter(BusinessSourceTypes.REALPAGE),
  //   value: BusinessSourceTypes.REALPAGE,
  // },
  // {
  //   label: capitalizeFirstLatter(BusinessSourceTypes.RENTMANAGER),
  //   value: BusinessSourceTypes.RENTMANAGER,
  // },
  //
  // {
  //   label: capitalizeFirstLatter(BusinessSourceTypes.REALPAGE_RPX),
  //   value: BusinessSourceTypes.REALPAGE_RPX,
  // },
  // {
  //   label: capitalizeFirstLatter(BusinessSourceTypes.MRI),
  //   value: BusinessSourceTypes.MRI,
  // },
  // {
  //   label: capitalizeFirstLatter(BusinessSourceTypes.RESMAN),
  //   value: BusinessSourceTypes.RESMAN,
  // },
];
