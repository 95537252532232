import get from 'lodash.get';
import qs from 'qs';
import HttpError from '@/errors/HttpError';
import store from '@/store';

const axios = require('axios').default; // Using `require` instead of `import` for better code completion

const config = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));

axiosInstance.interceptors.response.use(getResponseData, handleResponseError);

const Axios = {
  install,
};

export default Axios;

export {
  axiosInstance as axios,
};

function handleResponseError(error) {
  const status = get(error, 'response.status');
  if ([401, 403, 419].includes(status) && isLoggedIn()) {
    store.dispatch('Auth/logout');
    window.location.reload();
    return Promise.reject(new HttpError(''));
  }
  const errorMessage = getErrorMessage(error);
  return Promise.reject(new HttpError(errorMessage, error.response));
}

function getResponseData(response) {
  return response.data;
}

function install(Vue) {
  Vue.prototype.$axios = axiosInstance;
}

function requestInterceptor(config) {
  return serializeParams(config);
}

function serializeParams(config) {
  config.paramsSerializer = (params) => qs.stringify(params, { skipNulls: true });

  return config;
}

function isLoggedIn() {
  return store.getters['Auth/isLoggedIn'];
}

function getErrorMessage(error) {
  let errorMessage = error.response.data.message;

  if (typeof error.response.data.error !== 'undefined') {
    errorMessage = error.response.data.error;
  }
  if (typeof error.response.data.errors !== 'undefined') {
    errorMessage = Object.values(
      error.response.data.errors,
    )[0][0];
  }
  return errorMessage;
}
