export function getAvatarInitials(name) {
  function intToRGB(i) {
    // eslint-disable-next-line no-bitwise
    const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
    return '00000'.substring(0, 6 - c.length) + c;
  }

  function getContrastColor(hexColor, threshold = 128, defaultColor = '#000000') {
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > threshold ? defaultColor : '#ffffff';
  }

  try {
    const initials = name
      .split(' ')
      .map((name) => name.charAt(0).toUpperCase())
      .join('');

    let hashCode = 0;
    for (let i = 0; i < name.length; i++) {
      // eslint-disable-next-line no-bitwise
      hashCode = name.charCodeAt(i) + ((hashCode << 5) - hashCode);
    }
    const color = intToRGB(hashCode);
    const textColor = getContrastColor(color, 100, '#ffffff');

    return {
      initials,
      color,
      textColor,
    };
  } catch {
    return {
      initials: '',
      color: '#FFFFFF',
      textColor: '#FFFFFF',
    };
  }
}
