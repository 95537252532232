<script>
export default {
  functional: true,

  props: {
    bumpIf: Boolean,
    depth: {
      type: Number,
      required: false,
      default: 1,
    },
  },

  render(h, context) {
    const { props } = context;
    let { children } = context;

    if (props.bumpIf) {
      return children;
    }

    for (let i = 0; i < props.depth; i++) {
      children = children
        .reduce((acc, val) => acc.concat(val), [])
        .map((child) => {
          if (child.children) {
            return child.children;
          }
          return child.componentOptions != null ? child.componentOptions.children : null;
        })
        .filter((list) => list); // remove any null and void
    }

    return children;
  },
};
</script>
