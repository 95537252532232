import Role from '@/constants/Role';
import { axios } from '@/plugins/Axios';
import * as Sentry from '@/utils/SentryUtil';
import { sortUsersByFullName } from '@/utils/UserUtil';
import * as moment from 'moment';

export default {
  namespaced: true,
  state: {
    user: undefined,
    business: undefined,
    imposter: undefined,
  },
  getters: {
    isLoggedIn,
    userId,
    user,
    imposter,
    business,
    subscription,
    lastSubscription,
    lastBusinessSource,
    businessSyndicated,
    isZillowSyndicationEnabled,
    isZumperSyndicationEnabled,
    isApartmentsDotComSyndicationEnabled,
    accountIsInactive,
    plan,
    hasInitialCalendarConnection: (state) => state.user?.has_initial_calendar_connection,
  },
  mutations: {
    setUser,
    setBusiness,
    setImposter,
    deleteUser,
    deleteImposter,
    deleteBusiness,
  },
  actions: {
    login,
    createUserWithEmail,
    updateSignupDetails,
    resendVerificationEmail,
    verifyAccountOwner,
    registerBusinessMember,
    getInvitedUser,
    requestResetPassword,
    resetPassword,
    uploadAvatar,
    updateUser,
    updateBusiness,
    me,
    getTeamMembers,
    logout,
    deleteImpersonation,
    uploadPicture,
    setMeState,
  },
};

function isLoggedIn(state) {
  return (
    typeof state.user === 'object'
        && state.user !== null
        && isPmUser(state.user)
  );
}

function userId(state) {
  return (
    typeof state.user === 'object'
        && state.user !== null)
    ? state.user.id
    : null;
}

function user(state) {
  return (
    typeof state.user === 'object'
        && state.user !== null)
    ? state.user
    : null;
}

function imposter(state) {
  return (
    typeof state.imposter === 'object'
    && state.imposter !== null)
    ? state.imposter
    : null;
}

function business(state) {
  return (
    typeof state.user === 'object'
        && state.user !== null
        && state.user.business)
    ? state.user.business
    : null;
}

function subscription(state, getters) {
  return getters.business ? getters.business.subscription : null;
}

function lastSubscription(state, getters) {
  return getters.business?.last_subscription[0] ?? null;
}

function lastBusinessSource(state, getters) {
  return getters.business ? getters.business.last_business_source : null;
}

function businessSyndicated(state, getters) {
  return Boolean(getters.isZillowSyndicationEnabled || getters.isZumperSyndicationEnabled || getters.isApartmentsDotComSyndicationEnabled);
}

function isZillowSyndicationEnabled(state, getters) {
  return Boolean(getters.business?.zillow_syndication_enabled_at);
}

function isZumperSyndicationEnabled(state, getters) {
  return Boolean(getters.business?.zumper_syndication_enabled_at);
}

function isApartmentsDotComSyndicationEnabled(state, getters) {
  return Boolean(getters.business?.apartments_dot_com_syndication_enabled_at);
}

function accountIsInactive(state, getters) {
  return getters.business
    ? Boolean((!getters.subscription && getters.lastSubscription))
    : false;
}

function plan(state, getters) {
  return getters.subscription ? getters.subscription.plan : {};
}

function setUser(state, user) {
  state.user = {
    ...state.user,
    ...user,
  };
  Sentry.setUser(user);
}

function setBusiness(state, business) {
  state.user.business = {
    ...state.user.business,
    ...business,
  };
}

function setImposter(state, imposter) {
  state.imposter = imposter;
}

function deleteUser(state) {
  state.user = undefined;
  Sentry.deleteUser();
  if (window.FrontChat) {
    window.FrontChat('shutdown', { clearSession: true });
  }
}

function deleteImposter(state) {
  state.imposter = undefined;
}

function deleteBusiness(state) {
  state.business = undefined;
}

async function login(context, payload) {
  try {
    await axios.get('../sanctum/csrf-cookie');
  } catch (error) {}
  const response = await axios.post('pm/login', payload);
  const user = response.user;
  if (!isPmUser(user)) {
    return 'NOT_A_PM_USER';
  }
  setMeState(context, response);
  return user;
}

async function logout(context, payload) {
  context.commit('deleteUser');
  context.commit('deleteImposter');
  context.commit('deleteBusiness');
  if (window.FrontChat) {
    window.FrontChat('shutdown', { clearSession: true });
  }
  await axios.post('auth/logout', payload);
}

async function createUserWithEmail(context, payload) {
  const user = await axios.post('pm/signup', payload);
  context.commit('setUser', user);
}

async function updateSignupDetails(context, payload) {
  const user = await axios.put(`pm/signup/${this.getters['Auth/userId']}`, payload);
  context.commit('setUser', user);
  return user;
}

async function resendVerificationEmail(context, payload) {
  const user = await axios.post(`pm/signup/${this.getters['Auth/userId']}/resend-verification`, payload);
  context.commit('setUser', user);
}

async function verifyAccountOwner(context, payload) {
  const response = await axios.get(`pm/signup/${payload.id}/verify?signature=${payload.signature}`);
  const user = response.user;
  setMeState(context, response);
  return user;
}

async function registerBusinessMember(context, payload) {
  const user = await axios.put(`auth/setup/${payload.token}`, payload.data);
  context.commit('setUser', user);
  return user;
}

async function getInvitedUser(context, token) {
  return await axios.get(`auth/users/${token}`);
}

async function requestResetPassword(context, payload) {
  await axios.post('public/request-reset-password', payload);
}

async function resetPassword(context, payload) {
  await axios.post('public/reset-password', payload);
}

async function updateUser(context, payload) {
  await axios.put(`users/${this.getters['Auth/userId']}`, payload);
  const response = await me(context);
  return response.user;
}

async function uploadAvatar(context, payload) {
  const response = await axios.post(`users/${this.getters['Auth/userId']}/avatar-upload`, payload);
  if (response.avatar_file_url) {
    context.commit('setUser', { avatar_file_url: response.avatar_file_url });
  }
  return response;
}

async function updateBusiness(context, payload) {
  const response = await axios.put(`businesses/${this.getters['Auth/business'].id}`, payload);
  context.dispatch('me');
  return response;
}

async function deleteImpersonation(context) {
  const response = await axios.delete('impersonations');
  context.commit('deleteUser');
  context.commit('deleteImposter');
  return response;
}

async function me(context) {
  const response = await axios.get('pm/users/me');
  if (response.imposter) {
    context.commit('setImposter', response.imposter);
  }
  setMeState(context, response);
  return response.user;
}

async function getTeamMembers(context, params) {
  let teamMembers = await axios.get('pm/users', { params });

  teamMembers = teamMembers.data ?? teamMembers;

  const pendingMembers = [];
  const verifiedMembers = [];

  teamMembers.forEach((member) => (member.verified ? verifiedMembers.push(member) : pendingMembers.push(member)));

  return [
    ...pendingMembers.sort((a, b) => b.id - a.id),
    ...sortUsersByFullName(verifiedMembers),
  ];
}

function isPmUser(user) {
  return (
    user.role === Role.ACCOUNT_OWNER || user.role === Role.MEMBER
  );
}

function setMeState(context, response) {
  context.commit('setUser', response.user);
  context.commit('Notification/setNotifications', response.notifications, { root: true });
  context.commit('Settings/setSettings', response.settings, { root: true });
  context.commit('Prices/setPrices', response.prices, { root: true });
  if (response.user.business?.source) {
    context.commit('BusinessSource/set', response.user.business.source, { root: true });
  }

  if (response.user?.selectedCalendars) {
    const selectedCalendarIds = (response.user.selectedCalendars.filter(Boolean) ?? []).map((c) => c.id);
    context.commit('Calendar/setSelectedCalendars', selectedCalendarIds, { root: true });
  }
}

async function uploadPicture(context, payload) {
  return await axios.post('pm/logo', payload);
}
