<template>
  <div
    :class="badge.color"
    class="text-center status-badge"
  >
    <ElTooltip
      :disabled="$viewport.lt.md || !badge.tooltip"
      placement="bottom-start"
    >
      <template slot="content">
        <span
          v-if="badge.tooltip"
          class="text-white"
        >
          {{ badge.tooltip }}
        </span>
      </template>
      <span
        class="font-13"
        :class="{ 'font-weight-strong': badge.bold }"
        :style="{
          minWidth: !fixedWidth && badge.minWidth ? badge.minWidth : '',
          width: fixedWidth ? fixedWidth : null,
        }"
      >
        {{ badge.label }}
        <i
          v-if="badge.tooltip"
          class="sdicon-question-circle font-13"
        />
      </span>
    </ElTooltip>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';

import { getBadgeFormatStatus } from '@/utils/ScheduleUtil';
import { getReportsBadgeFormatStatus } from '@/utils/ConditionReportUtil';
import { getInquiryBadgeFormatStatus } from '@/utils/InquiryUtils';

export default {
  name: 'SdStatusBadge',
  props: {
    type: {
      type: String,
      default: '',
    },
    fixedWidth: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const badge = computed(() => {
      if (props.type.startsWith('inquiry-')) {
        const status = props.type.split('inquiry-')[1];
        return getInquiryBadgeFormatStatus(status);
      }

      if (props.type.startsWith('condition-report-')) {
        return getReportsBadgeFormatStatus(props.type);
      }
      return getBadgeFormatStatus(props.type);
    });
    return {
      props,
      badge,
    };
  },
};
</script>

<style lang="scss">
.status-badge {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  line-height: 1rem;
  border-radius: $app-border-radius;
  display: inline-flex;
  font-size: $--font-size-small;

  &.blue {
    background: theme-color("primary-lightest");
    color: theme-color("primary");
  }

  &.gray {
    background: gray-color("lighter");
    color: gray-color("");
  }

  &.orange {
    background: theme-color("warning-light");
    color: theme-color("warning");
  }

  &.red {
    background: theme-color("danger-light");
    color: theme-color("danger");
  }

  &.peach {
    background: theme-color("peach-light");
    color: theme-color("peach");
  }

  &.green {
    background: theme-color("greeneyes-light");
    color: theme-color("greeneyes");
  }

  &.purple {
    background: theme-color("purple-light");
    color: theme-color("purple");
  }

  &.malibu {
    background: theme-color("malibu");
    color: theme-color("malibu");
  }

  &.cornflower-blue-light {
    background: theme-color("cornflower-blue-light");
    color: theme-color("cornflower-blue-light");
  }

  &.processing {
    background: theme-color("parchment");
    color: theme-color("roti");
  }

  &.waiting-for-tenant {
    background: theme-color("pink-light");
    color: theme-color("pink");
  }

  &.scheduled {
    background: theme-color("moon-raker");
    color: theme-color("medium-purple");
  }

  &.finalizing {
    background: theme-color("hawkes-blue");
    color: theme-color("cornflower-blue");
  }

  &.on-hold {
    background: theme-color("mischka");
    color: theme-color("storm-gray");
  }
}
</style>
