import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    get,
  },
};

function get() {
  return axios.get('pm/plans');
}
