import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {
    property: {
      updated_at: null,
    },
  },
  mutations: {
    setProperty,
  },
  actions: {
    get,
    find,
    addProperty,
    update,
    patch,
    destroy,
    uploadPicture,
    pauseShowing,
    stopShowing,
    syncAll,
  },
};

function setProperty(state, property) {
  state.property = property;
}

async function get(context, params) {
  const page = params.page ?? 1;
  return await axios.get('pm/properties', {
    params: {
      page,
      only_own_properties: false,
      limit: -1,
      with_stats: page === 1,
      ...params,
    },
  });
}

async function find(context, id) {
  return await axios.get(`properties/${id}`);
}

async function addProperty({ commit }, payload) {
  const response = await axios.post('properties', payload);
  commit('setProperty', { ...response, units: payload.units });
  return response;
}

async function destroy(context, id) {
  return await axios.delete(`properties/${id}`);
}

async function update({ commit }, payload) {
  const response = await axios.put(`pm/properties/${payload.id}`, payload);
  commit('setProperty', response);
  return response;
}

async function patch(context, payload) {
  return await axios.patch(`properties/${payload.id}`, payload);
}

async function uploadPicture(context, payload) {
  return await axios.post('pm/property-picture', payload);
}

async function pauseShowing(context, id) {
  return await axios.post(`properties/${id}`);
}

async function stopShowing(context, id) {
  return await axios.post(`properties/${id}`);
}

async function syncAll(context) {
  return await axios.post('pm/properties/sync');
}
