import { ref } from '@vue/composition-api';
<template>
  <div class="container">
    <slot
      v-if="isLoadError"
      name="loading-error"
    />
    <slot
      v-if="!isImageLoaded && isLoadError === null"
      name="loading-placeholder"
    />
    <img
      v-if="src && isLoadError === null"
      :src="src"
      @error="handleImageError"
      @load="handleImageLoaded"
    >
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  props: {
    src: {
      type: String,
      default: null,
    },
  },
  setup() {
    const isImageLoaded = ref(false);
    const isLoadError = ref(null);
    return {
      isImageLoaded,
      isLoadError,
      handleImageError,
      handleImageLoaded,
    };

    function handleImageLoaded() {
      isImageLoaded.value = true;
    }

    function handleImageError() {
      isLoadError.value = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  overflow: hidden;

  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}
</style>
