<template>
  <ElSelect
    v-model="currentRangeRef"
    size="medium"
    class="sd-days-range-select"
    :disabled="disabled"
    @change="emitRange"
  >
    <ElOption
      v-for="range in daysAgo"
      :key="range"
      :value="range"
      :label="range===1 ? 'Last 24 hours' : `Last ${range} days`"
    />
  </ElSelect>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';

import momentUtil from '../../../utils/MomentUtil';

const daysAgo = [1, 7, 30, 60, 90];
export default {
  name: 'SdDaysRangeSelect',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const currentRangeRef = ref(30);
    onMounted(emitRange);
    return {
      currentRangeRef,
      emitRange,
      daysAgo,
    };
    function emitRange() {
      const to = momentUtil(null, null).toServerFormat();
      const from = momentUtil(null, null).subtract(currentRangeRef.value, 'days').toServerFormat();
      context.emit('change', { from, to });
    }
  },
};
</script>
<style lang="scss">
    .sd-days-range-select {
        .el-input .el-input__inner {
            color: gray-color('dark');
        }
    }
</style>
