import Vue from 'vue';

import * as ElementComponents from './ElementComponents';
import * as SdCommonComponents from './common';

const components = {
  ...ElementComponents,
  ...SdCommonComponents,
};

export default components;

export const registerComponents = () => {
  Object.keys(components).forEach((componentKey) => {
    const component = components[componentKey];
    const componentName = component.name || componentKey;
    Vue.component(componentName, component);
  });
};
