import VisaCardImg from '../assets/payment/Visa.png';
import MasterCardCardImg from '../assets/payment/MasterCard.png';
import DinersClubCardImg from '../assets/payment/Diners Club.png';
import AmericanExpressCardImg from '../assets/payment/American Express.png';
import DiscoverCardImg from '../assets/payment/Discover.png';
import JCBCardImg from '../assets/payment/JCB.png';

const cardsImages = {
  VisaCardImg,
  MasterCardCardImg,
  DinersClubCardImg,
  AmericanExpressCardImg,
  DiscoverCardImg,
  JCBCardImg,
};

function getCardImage(brand) {
  return cardsImages[`${brand.trim()}CardImg`];
}

function getCardSummaryString(card) {
  return `${card.brand} ending ${card.last4} | Expires ${card.exp_month}/${String(card.exp_year).slice(2)}`;
}

export { getCardImage, getCardSummaryString };
