import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {
    cardLast4: null,
  },
  getters: {
    cardLast4: (state) => state.cardLast4,
  },
  mutations: {
    setCardLast4,
  },
  actions: {
    getPaymentSources,
    addPaymentSource,
    addBusinessCredits,
    saveRechargeAmount,
  },
};

function setCardLast4(state, cardLast4) {
  state.cardLast4 = cardLast4;
}

async function getPaymentSources(context) {
  const paymentSources = await axios.get(`businesses/${this.getters['Auth/business'].id}/payment-sources`);
  const last4 = paymentSources.find((source) => source.type === 'card').card.last4;
  context.commit('setCardLast4', last4);

  return paymentSources;
}

async function addPaymentSource(context, payload) {
  const paymentSource = await axios.post(`businesses/${this.getters['Auth/business'].id}/payment-sources`, payload);
  const last4 = paymentSource.card.last4;
  context.commit('setCardLast4', last4);

  return paymentSource;
}

async function addBusinessCredits(context, amount) {
  return await axios.post('pm/credits', { amount });
}

async function saveRechargeAmount(context, amount) {
  return await axios.put('pm/credits', {
    recurring_credits_amount: amount,
  });
}
