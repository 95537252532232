import { axios } from '@/plugins/Axios';
import CountryOptions from '../constants/CountryOptions';

export default {
  namespaced: true,
  state: {
    businessCreditsBalance: null,
  },
  mutations: {
    setBusinessCreditsBalance,
  },
  getters: {
    businessCreditsBalance: (state) => state.businessCreditsBalance,
  },
  actions: {
    addNewAccountOwner,
    addNewTeamMember,
    removeMember,
    sendInvitationEmail,
    getListingTags,
    getTours,
    getToursCounters,
    getTour,
    get,
    me,
    dashboardMap,
    dashboardMetrics,
    dashboardLeaderboard,
    dashboardLikelyToApply,
    getTransactions,
    generateToken,
    updateWebhookIntegration,
    revokeToken,
  },
};

function setBusinessCreditsBalance(state, businessCreditsBalance) {
  state.businessCreditsBalance = businessCreditsBalance === 0 ? Math.abs(businessCreditsBalance) : businessCreditsBalance * -1;
}

async function getListingTags(context, type = null) {
  return await axios.get('pm/tags', { params: { type } });
}

async function generateToken(context) {
  return await axios.get('pm/token');
}

async function updateWebhookIntegration(context, params) {
  return await axios.post('pm/webhook-integration', params);
}

async function revokeToken(context) {
  return await axios.delete('pm/token');
}

async function addNewAccountOwner(context, newMemberPayload) {
  return await axios.post('owners', newMemberPayload);
}

async function addNewTeamMember(context, newMemberPayload) {
  return await axios.post('owners', newMemberPayload);
}

async function removeMember(context, params) {
  let url = `users/${params.userId}`;
  if (params.destinationListingManagerId) {
    url += `?with-transfer=${params.destinationListingManagerId}`;
  }
  await axios.delete(url);
}

async function sendInvitationEmail(context, id) {
  return await axios.post(`users/${id}/send-invitation-mail`);
}

async function getTours(context, {
 from, to, statuses, units, sort,
}) {
  return await axios.get(`owners/${this.getters['Auth/userId']}/tours`, {
    params: {
      from,
      to,
      statuses,
      units,
      sort,
    },
  });
}

async function getToursCounters(context, { todayFrom, todayTo, units }) {
  return await axios.get(`businesses/${this.getters['Auth/business'].id}/tours/counters`, {
    params: {
      today_from: todayFrom,
      today_to: todayTo,
      units,
    },
  });
}

async function getTour(context, scheduleId) {
  return await axios.get(`owners/${this.getters['Auth/userId']}/tours/${scheduleId}`);
}

async function get(context) {
  const business = await axios.get(`businesses/${this.getters['Auth/business'].id}`);
  context.commit('setBusinessCreditsBalance', business.total_balance);

  return business;
}

async function me() {
  return await axios.get('pm/business');
}

async function dashboardMap() {
  return await axios.get(`businesses/${this.getters['Auth/business'].id}/dashboards/map`);
}

async function dashboardMetrics(context, params) {
  return await axios.get(`businesses/${this.getters['Auth/business'].id}/dashboards/metrics`, { params });
}

async function dashboardLeaderboard(context, params) {
  return await axios.get(`businesses/${this.getters['Auth/business'].id}/dashboards/leaderboard`, { params });
}

async function dashboardLikelyToApply() {
  return await axios.get(`businesses/${this.getters['Auth/business'].id}/dashboards/likely-to-apply`);
}

async function getTransactions(context, params) {
  return await axios.get(`businesses/${this.getters['Auth/business'].id}/transactions`, { params });
}
