import { ConditionReportStates } from '@/constants/ConditionReport';

function getReportsBadgeFormatStatus(type) {
  const badge = {
    label: '',
    color: 'gray',
    bold: true,
    minWidth: '85px',
  };
  switch (type) {
    case ConditionReportStates.ORDERED:
      badge.label = 'Ordered';
      badge.color = 'blue';
      break;
    case ConditionReportStates.PLANNING:
      badge.label = 'Processing';
      badge.color = 'processing';
      break;
    case ConditionReportStates.WAITING_FOR_TENANT:
      badge.label = 'Waiting for tenant';
      badge.color = 'waiting-for-tenant';
      break;
    case ConditionReportStates.SCHEDULED:
      badge.label = 'Scheduled';
      badge.color = 'scheduled';
      break;
    case ConditionReportStates.REVIEWING:
      badge.label = 'Finalizing';
      badge.color = 'finalizing';
      break;
    case ConditionReportStates.COMPLETED:
      badge.label = 'Completed';
      badge.color = 'green';
      break;
    case ConditionReportStates.ON_HOLD:
      badge.label = 'On hold - PM';
      badge.color = 'on-hold';
      break;
    case ConditionReportStates.ON_HOLD_TENANT:
      badge.label = 'On hold - Tenant';
      badge.color = 'on-hold';
      break;
  }
  return badge;
}

export {
  getReportsBadgeFormatStatus,
};
