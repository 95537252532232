import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  actions: {
    get,
    editInquiry,
  },
};

async function get() {
  return await axios.get('pm/leasing');
}

async function editInquiry(context, { id, applied }) {
  return await axios.put(`pm/leasing/${id}`, { applied });
}
