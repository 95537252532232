export {
  shortenName,
  determineArticle,
  getFileTypeByUrl,
  truncateText,
  capitalizeFirstLatter,
};

function capitalizeFirstLatter(string) {
  const typeSplit = string.split('_');
  string = typeSplit.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const rest = word.slice(1).toLowerCase();
    return firstLetter + rest;
  });
  return string.join(' ');
}

function truncateText(text, maxCharLength = 140, withEllipsis = true) {
  return text.length > maxCharLength
      ? `${text.substring(0, maxCharLength)}${withEllipsis ? '...' : ''}`
      : text;
}

function shortenName(fullName) {
  if (!fullName.includes(' ')) {
    return fullName;
  }
  const nameArr = fullName.split(' ');
  return `${nameArr[0]} ${nameArr[1].substr(0, 1)}.`;
}

function determineArticle(word) {
  // Convert the word to lowercase for easier comparison
  const lowercasedWord = word.toLowerCase();

  // Define the list of words that should be preceded by "an"
  const anWords = ['a', 'e', 'i', 'o', 'u'];

  // Check if the word starts with a vowel sound
  if (anWords.includes(lowercasedWord.charAt(0))) {
    return 'an';
  }

  return 'a';
}

function getFileTypeByUrl(url) {
  if (!url) {
    return null;
  }
  const fileExtension = url.split('.').pop().toLowerCase();
  const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'webp'];
  const documentExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

  if (imageExtensions.indexOf(fileExtension) !== -1) {
    return 'image';
  }

  if (documentExtensions.indexOf(fileExtension) !== -1) {
    return 'document';
  }

  return null;
}
