<template>
  <div class="action">
    <router-link
      v-if="action.routeData"
      :to="action.routeData"
    >
      <ElButton
        :loading="Boolean(action.isLoading)"
        :disabled="action.disabled || Boolean(action.isLoading)"
        :type="$viewport.gt.md || isForceDesktopView ? action.buttonType : 'text'"
        :class="action.buttonClass"
        :size="action.size || 'small'"
      >
        <span :class="{ 'icon-mobile-wrapper': $viewport.lt.md }">
          <i
            v-if="action.icon"
            :class="action.icon"
          />
        </span>
        {{ action.label }}
        <component
          :is="action.innerComponent"
          v-if="action.innerComponent"
        />
      </ElButton>
    </router-link>
    <ElButton
      v-else
      :type="$viewport.gt.md || isForceDesktopView ? action.buttonType : 'text'"
      :class="action.buttonClass"
      :loading="Boolean(action.isLoading)"
      :size="action.size || 'small'"
      :disabled="action.disabled || Boolean(action.isLoading)"
      @click="action.cb ? action.cb() : toggleUiFlag()"
    >
      <span :class="{ 'icon-mobile-wrapper': $viewport.lt.md }">
        <i
          v-if="action.icon"
          :class="action.icon"
        />
      </span>
      {{ action.label }}
      <component
        :is="action.innerComponent"
        v-if="action.innerComponent"
      />
    </ElButton>
  </div>
</template>

<script>
export default {
  name: 'SdActionButton',
  components: {},
  props: {
    action: {
      type: Object,
      required: true,
    },
    isForceDesktopView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    return {
      toggleUiFlag,
    };

    function toggleUiFlag() {
      context.emit('toggle-action-ui-flag');
    }
  },
};
</script>
<style lang="scss" scoped>
.action {
  position: relative;
  @media (max-width: 768px) {
    width: -webkit-fill-available;
    width: -moz-available;
    a,
    button {
      width: -webkit-fill-available;
      width: -moz-available;
      text-align: left;
    }
  }

  .icon-mobile-wrapper {
    width: 1.5rem;
    display: inline-block;
    text-align: left;

    i {
      margin: 0;
    }
  }

  .sdicon-trash:before {
    margin: 0;
  }
}
</style>
