import pluralize from 'pluralize';
import momentUtil from '@/utils/MomentUtil';
import moment from 'moment-timezone';
import DateTime from '@/constants/DateTime';

export function getTimeSelectRange(timezone, from, to = null, step = 30, labelFormat = DateTime.displayShortTime) {
  const times = [];

  // We take the original "from" date and clone it to prevent mutation
  // We then set the time to the start of the minute (:00) to allow accurate comparisons later on
  let fromDate = moment(from).startOf('minute');

  // We round the minutes to the next "step" minutes interval
  fromDate = momentUtil.roundUpToNearestMinutes(fromDate, step, true);

  // If provided, we use the "to" date, otherwise we assume the end of the day (start of next day -> 00:00)
  const toDate = to ? moment(to) : fromDate.clone().add(1, 'days').startOf('day');

  while (fromDate.isBefore(toDate)) {
    times.push({
      date: fromDate.toISOString(),
      label: fromDate.format(labelFormat),
    });

    fromDate.add(step, 'minutes');
  }

  return times;
}

/**
 *
 * @param valueInMinutes
 * @returns {string}  ex: 2 hours 30 minutes
 */
export function hoursAndMinutesStringFromMinutes(valueInMinutes) {
  let result = '';
  const hours = Math.floor(valueInMinutes / 60);
  const minutes = valueInMinutes % 60;
  if (hours !== 0) {
    result = `${hours} ${pluralize('hour', hours)}`;
  }
  if (minutes > 0) {
    result += ` ${minutes} ${pluralize('minute', minutes)}`;
  }
  return result;
}

export function diffInHumanFormat(fromMoment, toMoment) {
  let diffInMinutes = toMoment.diff(fromMoment, 'minutes', true);

  if (diffInMinutes > 1) {
    diffInMinutes = Math.ceil(diffInMinutes);
  } else {
    diffInMinutes = 0;
  }

  const days = Math.floor(diffInMinutes / 1440);
  if (days !== 0) {
    return `${days} ${pluralize('Day', days)}`;
  }

  const hours = Math.floor(diffInMinutes / 60);
  if (hours !== 0) {
    return `${hours} ${pluralize('Hour', hours)}`;
  }

  return `${diffInMinutes} Min`;
}

export function greeting() {
  const currentTime = new Date();
  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(currentTime.getHours());

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return 'Good afternoon';
  }
  if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return 'Good evening';
  }
  // Between dawn and noon
  return 'Good morning';
}

export function toServerFormat(date) {
  const givenDate = moment(date, 'YYYY-MM-DD HH:mm').toDate();
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(givenDate);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(givenDate);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(givenDate);

  return `${year}-${month}-${day}`;
}

export function formatShortDisplayWithTz(date, tz) {
  const timezone = tz || momentUtil.tz.guess();
  const momentDate = momentUtil(date, timezone);
  return momentDate.toDisplayFormat();
}

export function overrideAndformatActivityToPropertyTz({
                                                        activity,
                                                        dateAttributes = ['date', 'tour_at'],
                                                        activityTimezone = momentUtil.tz.guess(),
                                                      } = {
  dateAttributes: ['date', 'tour_at'], activityTimezone: momentUtil.tz.guess(),
}) {
  dateAttributes.forEach((attribute) => {
    if (activity[attribute]) {
      const timezone = attribute === 'date' ? momentUtil.tz.guess() : activityTimezone;
      activity[attribute] = formatShortDisplayWithTz(activity[attribute], timezone);
    }
  });
  return activity;
}
