import { updateLocale } from 'moment';

export default {
  serverFormat: 'YYYY-MM-DD HH:mm:ss',
  displayFormat: 'MMM D, YYYY | h:mmA',
  displayShortTime: 'h:mmA',
  displayFormatWithDay: 'ddd, MMM Do h:mmA',
  displayFormatWithDayAndYear: 'ddd, MMM Do YYYY h:mmA',
  displayFormatWithoutYear: 'MMM D | h:mmA',
  displayFormatMonthAndDay: 'MMM Do',
  displayFormatMonthDayAndYear: 'MMM D, YYYY',
  displayFormatMonthDayAndYearWithSt: 'MMM Do, YYYY',
  displayFormatFullMonthDayAndYearWithSt: 'MMMM Do, YYYY',
  displayFormatMonthWithDayAndShortTime: 'MMM D, h:mma',
  displayFormatMonthWithWeekdayAndShortTime: 'dddd MMM D, h:mma',
  dateFormat: 'MM/DD/YY',
  shortRelativeFormat: updateLocale('en', {
    relativeTime: {
      past: '%s ago',
      s(number, withoutSuffix) {
        return withoutSuffix ? 'now' : 'a few seconds';
      },
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mth',
      MM: '%dmth',
      y: '1y',
      yy: '%dy',
    },
  }),
};
