import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    get,
    cancel,
    postTourReview,
    getTourSurvey,
  },
};

async function get(context, params) {
  return await axios.get('tours', { params });
}

async function cancel(context, id) {
  return await axios.post(`tours/${id}/cancel`);
}

async function getTourSurvey(context, id) {
  return await axios.get(`tours/${id}/survey`);
}

async function postTourReview(context, params) {
  return await axios.post(`tours/${params.id}/completions`, params.payload);
}
