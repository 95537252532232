import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    add,
    schedule,
  },
};

async function add(context, payload) {
  return await axios.post('inquiries', payload);
}

async function schedule(context, data) {
  return await axios.post('pm/schedules', data.payload);
}
