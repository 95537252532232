import Vue from 'vue';
import Lazy from '@/directives/Lazy';
import ClickOutside from '@/directives/ClickOutside';

import NoScroll from '@/directives/NoScroll';
import * as ElementDirectives from './ElementDirectives';

const directives = {
  ...ElementDirectives,
  Lazy,
  ClickOutside,
  NoScroll,
};

export default directives;

export function registerDirectives() {
  Object.values(directives).forEach((directive) => {
    if (typeof directive.install !== 'undefined') {
      Vue.use(directive);
    } else {
      Vue.directive(directive.name, directive);
    }
  });
}
