<template>
  <header
    id="impersonation-header"
    class="row bg-red-dark text-white"
  >
    <div class="col">
      <div>
        <strong>Admin User:</strong>
        {{ $store.state.Auth.imposter.full_name }}
        ({{ $store.state.Auth.imposter.email }})
      </div>
      <div>
        <strong>You are viewing the dashboard as:</strong>
        {{ $store.state.Auth.user.full_name }}
        ({{ $store.state.Auth.user.email }})
        <span
          v-if="$store.state.Auth.user.business && $store.state.Auth.user.business.name"
        >, {{ $store.state.Auth.user.business.name }}
        </span>
      </div>
    </div>
    <div class="col-auto">
      <el-button @click="returnToAdminApp">
        Return to admin app
      </el-button>
    </div>
  </header>
</template>

<script>
export default {
  setup(props, context) {
    return { returnToAdminApp };

    async function returnToAdminApp() {
      const response = await context.root.$store.dispatch('Auth/deleteImpersonation');
      window.location.href = response.admin_ui_url;
    }
  },
};
</script>

<style lang="scss" scoped>
    #impersonation-header {
        padding: 1.25rem 0;
        margin: -1.5rem 0 0 0;
        width: 100%;
    }
</style>
