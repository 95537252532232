import get from 'lodash.get';
import isEqual from 'lodash.isequal';

import store from '../store';

export {
  init,
  identify,
  page,
};

/* eslint-disable  */
function init() {
    if (!process.env.VUE_APP_SEGMENT_WRITE_KEY) {
        return;
    }

    !function(){
        var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var t=analytics.methods[e];analytics[t]=analytics.factory(t)}analytics.load=function(e,t){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+e+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=t};analytics.SNIPPET_VERSION="4.1.0";
        analytics.load(process.env.VUE_APP_SEGMENT_WRITE_KEY);
    }}();
}

function identify(user, oldUser) {
  if (!process.env.VUE_APP_SEGMENT_WRITE_KEY) {
    return;
  }

  const formattedUser = formatUser(user);
  const formattedOldUser = formatUser(oldUser);
  const subscription = get(user, 'business.subscription');

  if (!user) {
    window.analytics.reset();
    return;
  }

  if (!subscription || isEqual(formattedUser, formattedOldUser)) {
    return;
  }

  // only identify subscribed users
  window.analytics.identify(user.id, formattedUser);

  if (user.business) {
    const groupTraits = formatGroupTraits(user);
    window.analytics.group(user.business.id, groupTraits, {
      integrations: {
        'All': true,
        'Stonly': false,
      },
    });
  }

}

function page(name) {
  if (!process.env.VUE_APP_SEGMENT_WRITE_KEY || !window.analytics) {
    return;
  }
  window.analytics.page(name, {
    integrations: {
      'All': true,
      'Stonly': false,
    },
  })
}

function formatUser(user) {
  if (!user || typeof user.id === 'undefined') {
    return {};
  }
  const formattedUser = {
    id: user.id,
    userId: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    name: user.full_name,
    email: user.email,
    createdAt: user.created_at,
    role: user.role,
    imposter_id: get(store.state.Auth.imposter, 'id'),
    markets: resolveUserMarketsCSV(user),
  };

  const business = user.business ? user.business : null;
  if (business) {
    formattedUser.company = {
      id: business.id,
      name: business.name,
    };
    formattedUser.business_id = business.id;
  }

  return formattedUser;
}

function formatGroupTraits(user) {
  const business = user.business;
  const accountOwner = business.account_owner || {};

  return  {
    business_name: business.name,
    business_id: business.id,
    contact_phone_number: business.formatted_contact_phone || accountOwner.formatted_phone_number,
    contact_email: business.contact_email || accountOwner.email,
    plan: get(business, 'subscription.plan.name'),
    markets: resolveUserMarketsCSV(user),
  }
}

function resolveUserMarketsCSV(user) {
  const business = user.business;
  if(!business) {
    return '';
  }
  const markets = business.markets || [];
  return  markets
    .map((market) => market.name)
    .join(',');
}
