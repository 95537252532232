<template>
  <div
    v-if="stripeForm !== null"
    class="sd-billing-info row"
  >
    <div class="col-md-12 position-relative">
      <div
        id="card-number"
        ref="cardNumber"
        class="cc-input empty"
      />
      <label for="card-number">Credit Card</label>
      <i class="icon sdicon-credit-card" />
    </div>
    <div class="col-6 position-relative">
      <div
        id="card-expiry"
        ref="cardExpiry"
        class="cc-input empty"
      />
      <label for="card-expiry">MM/YY</label>
      <i class="icon sdicon-calendar" />
    </div>
    <div class="col-6 position-relative">
      <div
        id="card-cvc"
        ref="cardCvc"
        class="cc-input empty"
      />
      <label for="card-cvc">CVC</label>
      <i class="icon sdicon-lock" />
    </div>
    <div class="col-md-12">
      <ElButton
        :loading="isLoading || uiFlags.isAddingCard"
        :disabled="isValidForm || uiFlags.isAddingCard"
        type="primary w-100"
        class="font-weight-normal font-17"
        @click="addCard"
      >
        Start Subscription
      </ElButton>
      <div
        v-if="stripeForm.generalError"
        class="col-md-12 font-12 text-danger"
      >
        {{ stripeForm.generalError }}
      </div>
    </div>
  </div>
</template>

<script>
    import {
 onMounted, onUpdated, ref, computed, reactive,
} from '@vue/composition-api';

    export default {
        name: 'SdBillingInfo',
        props: {
          isLoading: {
            type: Boolean,
            default: false,
          },
        },
        setup(props, context) {
            const uiFlags = reactive({
              isAddingCard: false,
              isStripeMounted: false,
            });

          // eslint-disable-next-line no-undef
            const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
            const elements = stripe.elements();
            const stripeForm = ref(null);
            const isValidForm = computed(computeIsValidForm);

            onMounted(() => {
                buildStripeCcForm();
            });
            onUpdated(() => {
                if (stripeForm.value && uiFlags.isStripeMounted === false) {
                    mountStripeForm();
                }
            });

            return {
                uiFlags,
                stripeForm,
                addCard,
                isValidForm,
            };

            async function addCard() {
                try {
                    uiFlags.isAddingCard = true;
                    const stripeResponse = await stripe.createSource(stripeForm.value.cardNumber.element);
                    if (!stripeResponse.error && stripeResponse.source) {
                        context.emit('card-created', stripeResponse.source);
                      }
                    uiFlags.isAddingCard = false;
                } catch (error) {
                    uiFlags.isAddingCard = false;
                    stripeForm.value.generalError = error.message || error;
                }
            }

            function buildStripeCcForm() {
                const form = {};
                form.cardNumber = { element: elements.create('cardNumber', defaultElementStyle) };
                form.cardExpiry = { element: elements.create('cardExpiry', defaultElementStyle) };
                form.cardCvc = { element: elements.create('cardCvc', defaultElementStyle) };
                stripeForm.value = form;
            }

            function mountStripeForm() {
                stripeForm.value.cardNumber.element.mount('#card-number');
                stripeForm.value.cardExpiry.element.mount('#card-expiry');
                stripeForm.value.cardCvc.element.mount('#card-cvc');
                floatingLabels();
                uiFlags.isStripeMounted = true;
            }

            function floatingLabels() {
              const inputs = document.querySelectorAll('.sd-billing-info .cc-input');
              Array.prototype.forEach.call(inputs, (input) => {
                input.addEventListener('focus', () => {
                  input.classList.add('focused');
                });
                input.addEventListener('blur', () => {
                  input.classList.remove('focused');
                });
                input.addEventListener('keyup', () => {
                  if (input.value && input.value.length === 0) {
                    input.classList.add('empty');
                  } else {
                    input.classList.remove('empty');
                  }
                });
              });
            }

            function computeIsValidForm() {
              if (!stripeForm) {
                return false;
              }
              const { cardNumber, cardExpiry, cardCvc } = stripeForm.value;
              return !cardNumber.element._complete || !cardExpiry.element._complete || !cardCvc.element._complete;
            }
        },
    };

    const defaultElementStyle = {
        style: {
            base: {
                fontSize: '15px',
                color: '#434449',
                '::placeholder': {
                  color: '#F5F7FB', // same as background to hide it and use floating label instead
                },
            },
        },
        classes: {
          focus: 'focused',
          empty: 'empty',
          invalid: 'invalid',
        },
    };

</script>

<style lang="scss">
// floating label style adjusted from stripe elements official example #2 https://stripe.dev/elements-examples/
    .sd-billing-info {
        label, .icon {
          transition-property: color, transform;
          transition-duration: 0.3s;
          transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
        }
        label {
          position: absolute;
          width: 100%;
          left: 3.5rem;
          bottom: 0.25rem;
          color: gray-color('dark');
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transform-origin: 0 50%;
          cursor: text;
          pointer-events: none;
        }
        .icon {
          position: absolute;
          bottom: 0.8rem;
          left: 1.5rem;
          font-size: 0.85rem;
          z-index: 1;
          color: gray-color('darker');
        }
        .cc-input {
          background-color: gray-color('lighter');
          padding: 1rem;
          height: 48px;
          border-radius: $app-border-radius;
          transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          will-change: opacity;
          &.focused, &:not(.empty) {
            padding-top: 22px;
            padding-left: 2.5rem;
          }
          &.focused + label, &:not(.empty) + label {
            transform: scale(0.75) translateY(-0.8rem) translateX(-2.5rem);
            cursor: default;
            + .icon {
              bottom: 0.45rem;
            }
          }
          &.invalid + label {
            color: theme-color('red');
          }
      }
    }
</style>
