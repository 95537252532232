import moment from 'moment-timezone';
import DateTime from '@/constants/DateTime';
import { toServerFormat } from '@/utils/DatetimeUtil';

moment.tz.setDefault('UTC');

const momentUtil = (date, timezone, format) => {
  let momentInstance = moment();

  if (date !== null) {
    momentInstance = moment(date, format);
  }

  if (typeof timezone === 'undefined') {
    throw new Error('You must specify timezone');
  }

  if (typeof timezone === 'string') {
    momentInstance.tz(timezone);
  }

  momentInstance.clone = function () {
    return addFormatMethods(moment(momentInstance));
  };

  return addFormatMethods(momentInstance);
};

Object.keys(moment).forEach((key) => momentUtil[key] = moment[key]);

function addFormatMethods(momentInstance) {
  momentInstance.toServerFormat = () => momentInstance.format(DateTime.serverFormat);
  momentInstance.toDateFormat = () => momentInstance.format(DateTime.dateFormat);
  momentInstance.toDisplayFormat = () => momentInstance.format(DateTime.displayFormat);
  momentInstance.toDisplayShortTimeFormat = () => momentInstance.format(DateTime.displayShortTime);
  momentInstance.toDisplayWithDayFormat = () => momentInstance.format(DateTime.displayFormatWithDay);
  momentInstance.toDisplayWithDayFormatAndYear = () => momentInstance.format(DateTime.displayFormatWithDayAndYear);
  momentInstance.toDisplayWithoutYearFormat = () => momentInstance.format(DateTime.displayFormatWithoutYear);
  momentInstance.toDisplayFormatMonthWithDayAndShortTimeFormat = () => momentInstance.format(DateTime.displayFormatMonthWithDayAndShortTime);
  momentInstance.toDisplayMonthWithWeekdayAndShortTimeFormat = () => momentInstance.format(DateTime.displayFormatMonthWithWeekdayAndShortTime);
  momentInstance.toDisplayMonthAndDayFormat = () => momentInstance.format(DateTime.displayFormatMonthAndDay);
  momentInstance.toDisplayFormatMonthDayAndYear = () => momentInstance.format(DateTime.displayFormatMonthDayAndYear);
  momentInstance.toDisplayFormatMonthDayAndYearSt = () => momentInstance.format(DateTime.displayFormatMonthDayAndYearWithSt);
  momentInstance.toDisplayFormatFullMonthDayAndYearSt = () => momentInstance.format(DateTime.displayFormatFullMonthDayAndYearWithSt);
  momentInstance.shortFromNow = () => momentInstance.fromNow(DateTime.shortRelativeFormat);
  return momentInstance;
}

/**
 * Change timezone without changing time.
 * For example: 2020-01-01 00:00:00 UTC => 2020-01-01 00:00:00 Asia/Jerusalem
 */
momentUtil.changeTz = (date, timezone) => {
  if (date instanceof Date) {
    date = toServerFormat(date);
  }
  return addFormatMethods(moment.tz(date, timezone));
};

momentUtil.toUtcSameTime = (date = null) => {
  const momentDate = moment(date);

  const utcDate = new Date(
    Date.UTC(
      momentDate.year(),
      momentDate.month(),
      momentDate.date(),
      momentDate.hour(),
      momentDate.minute(),
      momentDate.second(),
      momentDate.millisecond(),
    ),
  );

  return addFormatMethods(moment(utcDate));
};

momentUtil.roundUpToNearestMinutes = (date, minutes, roundMinutes = true) => {
  const dateMoment = moment(date);
  const remainder = (dateMoment.minute() % minutes);
  const missing = minutes - remainder;

  if (remainder > 0) {
    dateMoment.add(missing, 'minutes');
  }

  if (roundMinutes) {
    dateMoment.startOf('minute');
  }

  return addFormatMethods(dateMoment);
};

export default momentUtil;
