import store from '@/store';

const IdentityVerification = {
  ALL: 'all',
  SELF_SHOWING: 'self-showing',
};

const isPersonaVerificationEnabled = (leasePeriod) => leasePeriod?.has_identity_verification;

const isSelfHostedVerificationEnabled = (showingProfile, business) => {
  const isSelfShowing = Boolean(showingProfile?.is_self_showing);
  const isSelfHostedVerificationDisabled = Boolean(business?.self_showing_id_verification_disabled_at);

  return isSelfShowing && !isSelfHostedVerificationDisabled;
};

export const isLeadSelfHostedVerified = (lead, showingProfile) => {
  const isSelfShowing = Boolean(showingProfile?.is_self_showing);
  const isSelfHostedIdentified = Boolean(lead?.is_self_hosted_identified);

  return isSelfShowing && isSelfHostedIdentified;
};

/**
 * Checks if the inquiry has been explicitly verified by either of:
 * - Manually identified by PM
 * - Persona verification
 * - Self-Hosted verification
 */
export const isInquiryVerified = (inquiry, lead, showingProfile, business) => {
  const isManuallyVerified = Boolean(inquiry?.manually_identified_lead_at);
  const isPersonaVerified = Boolean(inquiry?.is_identified);
  const isSelfHostedVerified = isLeadSelfHostedVerified(lead, showingProfile, business);

  return isManuallyVerified || isPersonaVerified || isSelfHostedVerified;
};

/**
 * Checks if the inquiry is pending verification by taking into account
 * its verification status AND the verification settings that apply to the inquiry.
 * E.g. An inquiry may be unverified but not require verification.
 */
export const isInquiryPendingVerification = (inquiry, lead, showingProfile, leasePeriod) => {
  const business = store.getters['Auth/business'];
  const isManuallyVerified = Boolean(inquiry?.manually_identified_lead_at);

  // If the PM manually approves the lead, no further verification is pending
  if (isManuallyVerified) {
    return false;
  }

  const isInquiryPersonaVerificationEnabled = isPersonaVerificationEnabled(leasePeriod);
  const isInquirySelfHostedVerificationEnabled = isSelfHostedVerificationEnabled(showingProfile, business);

  // If no verification is enabled, no further verification is pending
  if (!isInquiryPersonaVerificationEnabled && !isInquirySelfHostedVerificationEnabled) {
    return false;
  }

  const isPersonaVerified = Boolean(inquiry?.is_identified);
  const isSelfHostedVerified = isLeadSelfHostedVerified(lead, showingProfile, business);

  // If any verification has been completed, no further verification is pending
  if (isPersonaVerified || isSelfHostedVerified) {
    return false;
  }

  return true;
};
