import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import createLogger from 'vuex/dist/logger';

import Calendar from '@/store/Calendar';
import Auth from './Auth';
import Ui from './Ui';
import AvailabilityDay from './AvailabilityDay';
import Codebox from './Codebox';
import Billing from './Billing';
import ConditionReport from './ConditionReport';
import Inquiry from './Inquiry';
import Business from './Business';
import BusinessSource from './BusinessSource';
import Listing from './Listing';
import Property from './Property';
import Prospect from './Prospect';
import Lease from './Lease';
import Device from './Device';
import Role from './Role';
import Schedule from './Schedule';
import Settings from './Settings';
import Prices from './Prices';
import ScreeningCriteria from './ScreeningCriteria';
import ShowingProfile from './ShowingProfile';
import Tour from './Tour';
import Unit from './Unit';
import Market from './Market';
import Notification from './Notification';
import Plan from './Plan';
import Subscription from './Subscription';
import BusinessSettings from './BusinessSettings';
import ScreeningTemplates from './ScreeningTemplates';

const strictMode = process.env.VUE_APP_ENV === 'local';

const vuexPersistenceInstance = new VuexPersistence({
  strictMode,
  key: 'storage',
  modules: ['Auth'],
  storage: window.localStorage,
});

const vuexLogger = createLogger({
  filter: () => process.env.VUE_APP_ENV !== 'production',
});

Vue.use(Vuex);

export default new Vuex.Store({
  mutations: {
    RESTORE_MUTATION: vuexPersistenceInstance.RESTORE_MUTATION,
  },
  modules: {
    Auth,
    Ui,
    AvailabilityDay,
    Codebox,
    Billing,
    ConditionReport,
    Inquiry,
    Business,
    BusinessSource,
    Lease,
    Device,
    Listing,
    Property,
    Prospect,
    Settings,
    Prices,
    Schedule,
    ScreeningCriteria,
    Tour,
    ShowingProfile,
    Role,
    Market,
    Unit,
    Notification,
    Plan,
    Subscription,
    Calendar,
    BusinessSettings,
    ScreeningTemplates,
  },
  plugins: [vuexPersistenceInstance.plugin, vuexLogger],
  strict: strictMode,
});
