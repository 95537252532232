import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    find,
    all,
    postNote,
  },
};

async function find(context, id) {
  return await axios.get(`pm/leads/${id}`);
}

async function all(context, id) {
  return await axios.get('pm/leads');
}

async function postNote(context, { leadId, note }) {
  return await axios.post(`pm/leads/${leadId}/notes`, {
    note,
  });
}
