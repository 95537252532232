function showErrorNotify(context, message, options = {}) {
  context.root.$notify({
    title: 'Error',
    type: 'error',
    message,
    ...options,
  });
}

function showSuccessNotify(context, message, options = {}, title = 'Success') {
  context.root.$notify({
    type: 'success',
    title,
    message,
    ...options,
  });
}

export { showErrorNotify, showSuccessNotify };
