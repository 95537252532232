import { axios } from '@/plugins/Axios';
import { BusinessSourceTypes } from '@/constants/BusinessSource';

export default {
  namespaced: true,
  state: {
    businessSource: null,
  },
  getters: {
    get,
    isIntegrationEnabled,
    isLegacyIntegrationEnabled,
    isAppfolioIntegration,
  },
  mutations: {
    set,
  },
  actions: {
    index,
    update,
    store,
    review,
    destroy,
    checkCredentials,
  },
};

async function index(context) {
  const businessSource = await axios.get('pm/business-sources');
  context.commit('set', businessSource);
  return businessSource;
}

async function store(context, payload) {
  return axios.post('pm/business-sources', payload);
}

async function update(context, { business_source_identifier, auto_sync, sync_inspection_reports }) {
  return axios.put('pm/business-sources', { business_source_identifier, auto_sync, sync_inspection_reports });
}

async function review() {
  return axios.get('pm/business-sources/review');
}

async function destroy(context, payload) {
  return axios.delete(`pm/business-sources/${payload.businessSourceId}`, payload);
}

async function checkCredentials(context, payload) {
  return axios.get(`pm/business-sources/check-credentials/${payload.businessSourceId}`, payload);
}

function set(state, businessSource) {
  state.businessSource = {
    ...businessSource,
  };
}
function get(state) {
  return state.businessSource;
}

function isLegacyIntegrationEnabled(state) {
  return isIntegrationEnabled(state) && state.businessSource.client_id === null;
}

function isIntegrationEnabled(state) {
  return state.businessSource ? Boolean(state.businessSource.synced_at) : false;
}

function isAppfolioIntegration(state) {
  return state.businessSource?.source_type === BusinessSourceTypes.APPFOLIO;
}

function isAppfolioIntegrationEnabled(state) {
  return isAppfolioIntegration(state) && isIntegrationEnabled(state);
}
