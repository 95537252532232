import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    index,
    show,
    generateAd,
  },
};

async function index(context) {
  return await axios.get('pm/listings');
}

async function show(context, unitId) {
  return await axios.get(`pm/units/${unitId}/listing`);
}

async function generateAd(context, payload) {
  return await axios.post(`pm/units/${payload.unitId}/listing/generate-ad`, payload);
}
