import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    changeRole,
  },
};

async function changeRole(context, { id, role }) {
  await axios.put(`pm/users/${id}`, {
    role,
  });
}
