export const ScheduleStatuses = {
  STATUS_PENDING: 'pending',
  STATUS_SCHEDULED: 'scheduled',
  STATUS_LOOKING_FOR_AGENT: 'looking_for_agent',
  STATUS_ABOUT_TO_EXPIRE: 'about_to_expire',
  STATUS_CANCELLED: 'cancelled',
  STATUS_AUTO_DELETED: 'auto_deleted',
};

export const ScheduleConfirmationStatuses = {
  WAITING_FOR_CONFIRMATION: 'waiting.for.confirmation',
  NOT_REQUIRED: 'not.required',
  NOT_CONFIRMED: 'not.confirmed',
  CONFIRMED: 'confirmed',
};

export const ScheduleTypes = {
  HIGH_AVAILABILITY: 'high_availability',
  RESTRICTED: 'restricted',
  OPEN_HOUSE: 'open_house',
};
