export {
  sortUsersByFullName,
  sortUsersByEmail,
};

function sortUsersByFullName(users) {
  return [...users].sort(
    (a, b) => a.first_name?.toLocaleLowerCase()?.localeCompare(b.first_name?.toLocaleLowerCase())
      || a.last_name?.toLocaleLowerCase()?.localeCompare(b.last_name?.toLocaleLowerCase()),
  );
}

function sortUsersByEmail(users) {
  return [...users].sort(
    (a, b) => a.email?.toLocaleLowerCase()?.localeCompare(b.email?.toLocaleLowerCase()),
  );
}
