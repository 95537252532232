<template>
  <span
    class="copy-wrapper"
    :class="isCopied ? copiedClass : ''"
    @click="copyToClipboard"
  >
    <slot />
    <template v-if="isCopied">
      <i
        v-if="icon"
        :class="iconCopied || icon"
      />
      <span
        v-if="textCopied"
        :class="textCssClass"
      >
        {{ textCopied }}
      </span>
      <div
        v-else
        class="bg-green rounded-circle d-inline-flex justify-content-center align-items-center"
        style="width: 21px; height: 21px"
      >
        <i class="sdicon-check text-white font-17" />
      </div>
    </template>
    <i
      v-else-if="isIcon"
      class="sdicon-copy"
    />
    <span v-else>
      <i
        v-if="icon"
        :class="isCopied ? iconCopied || icon : icon"
      />
      <span
        v-if="text"
        :class="textCssClass"
      >
        {{ isCopied ? textCopied || text : text }}
      </span>
      <template v-else>copy</template>
    </span>
    <input
      :id="inputId"
      :value="copy"
      class="clipboard-copy-input"
    >
  </span>
</template>

<script>
import { ref } from '@vue/composition-api';
import { showErrorNotify } from '@/utils/NotifyUtil';

export default {
  name: 'SdCopyToClipboard',
  props: {
    copy: {
      type: String,
      required: true,
    },
    copiedClass: {
      type: String,
      default: null,
    },
    inputId: {
      type: String,
      default: () => `copy-${Math.floor(Math.random() * 10000)}`,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconCopied: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    textCopied: {
      type: String,
      default: null,
    },
    textCssClass: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const isCopied = ref(false);
    return {
      isCopied,
      copyToClipboard,
    };

    async function copyToClipboard() {
      const copyText = document.getElementById(props.inputId);

      try {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(copyText.value);
        } else {
          copyText.focus();
          copyText.select();
          copyText.setSelectionRange(0, 99999); /* For mobile devices */
          document.execCommand('copy');
        }

        copyText.blur();
        this.isCopied = true;

        setTimeout(() => {
          this.isCopied = false;
        }, 3000);
      } catch (error) {
        showErrorNotify(context, error.message);
      }
    }
  },
};
</script>

<style scoped>
.copy-wrapper {
  cursor: pointer;
}

.clipboard-copy-input {
  opacity: 0;
  width: 0.75rem;
  height: 2px;
  border: none;
  text-indent: 2rem;
  background: transparent;
}
</style>
