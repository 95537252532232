import {
 Loading, Notification,
} from 'element-ui';

const elementPlugins = {
  $loading: Loading.service,
  $notify: Notification,
};

const install = (Vue) => {
  Vue.use(Loading.directive);

  Object.keys(elementPlugins).forEach((pluginKey) => {
    Vue.prototype[pluginKey] = elementPlugins[pluginKey];
  });
};

const ElementPlugins = {
  install,
};

export default ElementPlugins;
