<template>
  <ElDropdown
    trigger="click"
    hide-on-click
    class="pointer"
    @click.native.prevent
  >
    <div>
      <i class="sdicon-notification text-blue font-24" />
      <span
        v-if="notReadCount"
        id="notifications-badge"
      >
        {{ notReadCount > 9 ? `9+` : notReadCount }}
      </span>
    </div>
    <ElDropdownMenu>
      <div id="notifications">
        <div class="row no-gutters px-3 py-3 align-items-center">
          <div class="col font-weight-bold font-17">
            Notifications
          </div>
          <div
            v-if="notReadCount"
            class="col-auto text-gray-dark font-13 pointer"
            @click="readAllNotifications"
          >
            Mark all as read
          </div>
        </div>
        <div
          v-if="notifications===null"
          class="text-center"
        >
          <ElSpinner color="primary" />
        </div>
        <ElDropdownItem
          v-for="notification in notifications"
          v-else
          :key="notification.id"
          class="notification"
          :class="{ 'not-read': !notification.read_at }"
          @click.native="readNotification(notification)"
        >
          <router-link :to="notification.action_url">
            <div class="image">
              <div
                v-if="notification.image_url"
                :style="`background-image: url('${notification.image_url}')`"
              />
              <div
                v-else
                class="empty-state"
              />
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="content"
              v-html="notification.message"
            />
            <div class="time">
              {{ notification.created_at }}
            </div>
            <!-- eslint-enable vue/no-v-html -->
          </router-link>
        </ElDropdownItem>
        <div
          v-if="notifications !== null && !notifications.length"
          class="d-flex flex-column align-items-center py-3 mx-5"
        >
          <img
            src="@/assets/notifications-empty-state.svg"
            style="width: 100px;"
          >
          <div class="font-17 mt-3">
            Nothing here
          </div>
          <div class="font-15 text-gray-dark mt-1">
            You have 0 notifications
          </div>
        </div>
      </div>
    </ElDropdownMenu>
  </ElDropdown>
</template>

<script>
import {
  computed, onMounted, onUnmounted, watch,
} from '@vue/composition-api';
import pusherInstance from '@/utils/PusherUtil';
import softNotification from '../../assets/soft_notification.mp3';
import SetTitleByRoute from '../../router/SetTitleByRoute';

export default {
  name: 'SdNotifications',
  setup(props, context) {
    const { $store } = context.root;
    const notifications = computed(computeNotifications);
    const notReadCount = computed(computeNotReadCount);
    watch(notReadCount, syncFaviconAndTitle);

    onMounted(subscribeWebSocket);
    onUnmounted(unsubscribeWebSocket);

    return {
      notifications,
      notReadCount,
      readAllNotifications,
      readNotification,
    };

    function computeNotifications() {
      if (!$store.state.Notification.notifications) {
        return [];
      }
      return $store.state.Notification.notifications;
    }

    function computeNotReadCount() {
      if (notifications.value === null) {
        return 0;
      }

      return notifications.value.filter((notification) => !notification.read_at).length;
    }

    function syncFaviconAndTitle(notReadCount) {
      document.title = `${SetTitleByRoute(context.root.$route)} ${notReadCount.value ? `(${notReadCount.value})` : ''}`;
      const faviconLinkEl = document.querySelector("link[rel='icon']");
      faviconLinkEl.href = notReadCount.value ? '/favicon-notification.ico' : '/favicon.ico';
    }

    function subscribeWebSocket() {
      pusherInstance.subscribe(`user-${$store.getters['Auth/userId']}`)
        .bind('notification', async ({ notification }) => {
          const isNotificationExists = notifications.value.findIndex((n) => n.id === notification.id);
          if (isNotificationExists !== -1) {
            return;
          }
          $store.commit('Notification/addNewNotification', notification);
          const notificationAudio = new Audio(softNotification);

          try {
            await notificationAudio.play();
          } catch (error) {}
        });
    }

    function unsubscribeWebSocket() {
      pusherInstance.unsubscribe(`user-${$store.getters['Auth/userId']}`);
    }

    async function readAllNotifications() {
      await $store.dispatch('Notification/readAll');
    }

    async function readNotification(notification) {
      await $store.dispatch('Notification/read', notification);
    }
  },
};
</script>

<style lang="scss">
#notifications-badge {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background: theme-color('red');
    display: flex;
    color: $white;
    min-width: 14px;
    padding: 0 3px;
    height: 14px;
    font-size: 9px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    line-height: 14px;
    transform: translateX(-25%) translateY(25%);
}

#notifications {
    width: 427px;
    max-height: 57px + 70px * 7;
    overflow-y: auto;
    border-radius: $app-border-radius;
    background: $white;

    @include media-breakpoint-down(md) {
        width: initial;
    }

    .notification {
        padding: 1rem;
        font-size: 1rem;
        line-height: inherit;

        &:not(:last-of-type) {
            border-bottom: 1px solid gray-color(light);
        }

        &.not-read {
            background: rgba(theme-color(primary), 0.1);
        }

        &:hover {
            background: rgba(theme-color(primary), 0.15);
        }

        > a {
            display: flex;

            &, &:hover {
                color: $--color-text-primary;
            }

            & > *:not(:last-child) {
                margin-right: 1rem;
            }

            > .image {
                > div {
                    width: 36px;
                    height: 36px;
                    border-radius: $app-border-radius;
                    background-size: cover;
                    background-position: center center;

                    &.empty-state {
                        background-image: url('../../assets/property-image-fallback-square.svg');
                    }
                }
            }

            > .content {
                flex: 1;
                font-size: 13px;
            }

            > .time {
                color: gray-color(dark);
                font-size: 11px;
            }
        }
    }
}

.el-dropdown-menu {
    padding: 0;
}
</style>
