<template>
  <footer :class="$viewport.lt.md ? 'mobile' : ''">
    <slot>
      <div
        class="row no-gutters justify-content-between"
        :class="$viewport.lt.md ? 'flex-column' : ''"
      >
        <SdConditionallyBumpChildren :bump-if="$viewport.gt.md">
          <div
            id="right"
            class="col"
          >
            <a
              class="text-gray-dark"
              href="https://help.showdigs.com"
              target="_blank"
            >Help & Support</a>
            <a
              class="text-gray-dark"
              href="https://www.showdigs.com/terms"
              target="_blank"
            >TOC</a>
            <a
              class="text-gray-dark"
              href="https://www.showdigs.com/privacy"
              target="_blank"
            >Privacy</a>
          </div>
        </SdConditionallyBumpChildren>
        <SdConditionallyBumpChildren :bump-if="$viewport.gt.md">
          <div
            id="left"
            class="col-auto"
          >
            <div class="row align-items-center">
              <a
                href="https://www.linkedin.com/company/showdigs/"
                class="col-auto text-gray-dark"
                target="_blank"
                rel="noopener"
              >
                <i class="sdicon-linkedin" />
              </a>
              <a
                href="https://twitter.com/showdigshq"
                class="col-auto text-gray-dark"
                target="_blank"
                rel="noopener"
              >
                <i class="sdicon-twitter" />
              </a>
              <a
                href="https://www.facebook.com/showdigsHQ/"
                class="col-auto text-gray-dark"
                target="_blank"
                rel="noopener"
              >
                <i class="sdicon-facebook" />
              </a>

              <div
                v-if="$viewport.gt.sm"
                id="copyright"
                class="col text-gray-dark font-13"
              >
                <img
                  src="@/assets/icon.svg"
                  class="mr-2"
                >
                <span>Showdigs {{ new Date().getFullYear() }}</span>
              </div>
            </div>
          </div>
        </SdConditionallyBumpChildren>
      </div>
    </slot>
  </footer>
</template>

<script>
export default {
  name: 'SdFooter',
};
</script>

<style lang="scss" scoped>
    footer {
        padding: 1.5rem 2rem;
        border-top: 1px solid gray-color();

        #right,
        #left {
            display: flex;
            flex-direction: column;

            > * {
                line-height: 1.25rem;
            }

            > *:not(:last-child) {
                margin-bottom: 0.75rem;
            }
        }

        @include media-breakpoint-up(md) {
            padding: 1rem 4.75rem;

            #right,
            #left {
                display: block;

                > *:not(:last-child) {
                    margin-bottom: 0;
                }
            }

            #right {
                > *:not(:last-child) {
                    margin-right: 3rem;
                }
            }

            #left {
                #copyright {
                    > img {
                        width: 1.25rem;
                    }
                }
            }
        }

        #right > a {
            color: gray-color(dark);
            font-size: $--font-size-small;
        }
    }

    footer.mobile {
        padding: 1rem 3rem 1rem 1rem;
        border-top: none;
    }
</style>
