import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {
    waitingForRegistrationResponse: false,
    availableCalendarsOptions: [],
    selectedCalendars: [],
  },
  getters: {
    availableCalendarsOptionsLength: (state) => state.availableCalendarsOptions?.length ?? 0,
    availableCalendarsOptions: (state) => state.availableCalendarsOptions,
    selectedCalendars: (state) => state.selectedCalendars || [],
    selectedCalendarsLength: (state) => state.selectedCalendars?.length ?? 0,
  },
  mutations: {
    setWaitingForRegistrationResponse,
    setAvailableCalendarsOptions,
    setSelectedCalendars,
  },
  actions: {
    load,
    registerIntegration,
    update,
    disconnectIntegration,
  },
};

function setWaitingForRegistrationResponse(state, bool) {
  state.waitingForRegistrationResponse = bool;
}

function setAvailableCalendarsOptions(state, availableCalendarsOptions) {
  state.availableCalendarsOptions = availableCalendarsOptions;
}

function setSelectedCalendars(state, selectedCalendars) {
  state.selectedCalendars = selectedCalendars;
}

async function load(context) {
  const data = await axios.get('pm/calendar');

  calculateAvailableCalendarsOptions(context, data.availableCalendars, data.selectedCalendars);

  return data;
}

async function registerIntegration(context, { code }) {
  context.commit('setWaitingForRegistrationResponse', true);
  const data = await axios.post('pm/calendar', {
    code,
  });

  return data;
}

async function update(context, calendarIds) {
  return await axios.put('pm/calendar', {
    calendars: calendarIds,
  });
}

async function disconnectIntegration(context) {
  setAvailableCalendarsOptions([]);
  context.commit('setSelectedCalendars', []);
  return await axios.delete('pm/calendar');
}

function calculateAvailableCalendarsOptions(context, availableCalendars, selectedCalendars) {
  if (!availableCalendars) {
    return;
  }

  // the primary calendar always exists in selected calendars, so
  // we remove it so it won't appear twice
  const primaryCalendarIndex = availableCalendars.findIndex((calendar) => calendar.is_primary === true);
  const primaryCalendar = availableCalendars.splice(primaryCalendarIndex, 1)[0];
  availableCalendars.unshift(primaryCalendar);

  const availableCalendarsOptions = availableCalendars.map((calendar) => {
    let label = calendar.name;
    if (calendar.is_primary) {
      label += ' (Primary)';
    }

    return {
      label,
      value: calendar.id,
      disabled: calendar.is_primary === true,
    };
  });

  context.commit('setAvailableCalendarsOptions', availableCalendarsOptions);
  context.commit('setSelectedCalendars', selectedCalendars.map((c) => c.id));
}
