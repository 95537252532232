import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    getAssigneeTour,
    takeOver,
    reassign,
    surrender,
    becomeRegularTour,
    becomeSelfTour,
    reschedule,
    getActivities,
  },
};

async function getAssigneeTour(context, payload) {
  const payloadData = {
    assignee_id: payload.assigneeId,
  };

  if (payload.tourAt) {
    payloadData.tour_at = payload.tourAt;
  }

  return await axios.post(`schedules/${payload.scheduleId}/assignee-tour`, payloadData);
}

async function takeOver(context, payload) {
  return await axios.post(`schedules/${payload.id}/takeovers`, { assignee_id: payload.assignee_id });
}

async function reassign(context, payload) {
  return await axios.put(`schedules/${payload.id}/takeovers`, { assignee_id: payload.assignee_id });
}

async function reschedule(context, payload) {
  return await axios.post(`pm/schedules/${payload.id}/reschedules`, { ...payload });
}

async function surrender(context, payload) {
  return await axios.delete(`pm/schedules/${payload.id}/takeovers`);
}

async function becomeRegularTour(context, payload) {
  return await axios.post(`pm/schedules/${payload.id}/become-regular-tour`);
}

async function becomeSelfTour(context, payload) {
  return await axios.post(`pm/schedules/${payload.id}/become-self-tour`);
}

async function getActivities(context, scheduleId) {
  return await axios.get(`schedules/${scheduleId}/activities`);
}
