export default {
  namespaced: true,
  state: {
    currentTestimonial: 0,
  },
  mutations: {
    setTestimonial,
  },
};

function setTestimonial(state, index) {
  state.currentTestimonial = index;
}
