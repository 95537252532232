export default {
  namespaced: true,
  state: {
    settings: [],
  },
  getters: {
    settings,
  },
  mutations: {
    setSettings,
  },
  actions: {},
};

function settings(state) {
  return state.settings.length
    ? state.settings
    : [];
}

function setSettings(state, settings) {
  state.settings = settings;
}
