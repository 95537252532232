import Vue from 'vue';

import Axios from './Axios';
import Viewport from './Viewport';
import ElementPlugins from './ElementPlugins';
import Pluralize from './Pluralize';
import Moment from './Moment';

const plugins = {
  Viewport,
  Axios,
  ElementPlugins,
  Pluralize,
  Moment,
};

export default plugins;

export function registerPlugins() {
  Object.values(plugins).forEach((plugin) => {
    Vue.use(plugin);
  });
}
